const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id:''
}
const arrangeData =(value)=>{
  const y=[];
  value.map(x=>{
    y.push({
      ...x,
      rating: Math.round(x.rating).toString()
    })
  })

  return y
}

const IndividualDriver = (state = initialState, action) => {
  switch (action.type) {
    case "GET_INDIVIDUAL_DRIVER":
      return {
        ...state,
        data: arrangeData(action.data),
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end
      }
    default:
      return state
  }
}

export default IndividualDriver