import Resturl from "../../../configs/url";
import axios from "axios";
import { loadingTrue, loadingFalse } from "../loading";
import { notification } from "../../../utility/notification";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, setInitialLoading) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
      type: "New",
      filter: "ALL",
    };
  }
  return async (dispatch) => {
    // dispatch(loadingTrue());
    await axios
      .post(Resturl.partner_get_list, params)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_INDIVIDUAL_DRIVER_TOTAL_COUNTS",
          total_counts: response.data.total_counts,
        });
        dispatch({
          type: "GET_PARTNER_NEW_REGISTERED",
          data: response.data.partners,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_partners,
          start: response.data.start,
          end: response.data.end,
          params,
        });
        // dispatch(loadingFalse());
        if (setInitialLoading) setInitialLoading(false);
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
  };
};

export const partnerStatusUpdate = (id, status, setInitialLoadingForDots) => {
  console.log(
    "🚀 ~ file: index.js:50 ~ partnerStatusUpdate ~ setInitialLoadingForDots:",
    setInitialLoadingForDots,
    status
  );
  return async (dispatch, getState) => {
    if (setInitialLoadingForDots) {
      setInitialLoadingForDots(true, id);
    }
    await axios
      .post(Resturl.partner_status_update, { partner_id: id, action: status })
      .then((response) => {
        dispatch({
          type: "PARTNER_STATUS_UPDATE",
          payload: response,
        });
        if (setInitialLoadingForDots) {
          setInitialLoadingForDots(false, id);
        }
        if (response.data.status === 0) {
          notification("Partner", response.data.message, "danger");
        } else {
          dispatch(getData(getState().partner_company_partner.params));
          notification("Partner", response.data.message, "success");
        }
      })
      .catch((err) => {
        if (setInitialLoadingForDots) {
          setInitialLoadingForDots(false, id);
        }
      });
  };
};
