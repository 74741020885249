const initialState = {
    data: [],
    params: null,
    totalPages: 0,
    totalRecords: 0,
    id:'',
    makes:[],
    active_vehicle_model:[],
    vehicle_model_detail:[]
  }
  const handleActiveModel=(data)=>{
    const x=[]
    data.map((value,index)=>{
      x.push({value:value._id,label:value.name})
    })
    return x
  }
  const handleFeature=(data)=>{
    return data
  }
  const handleMakes=(value)=>{
    const x=[]
    value.map((value,index)=>{
      x.push({ value:value._id, label: value.name })
    })
  return x
  }
  
  const DataListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_VEHICLE_MODEL":
        return {
          ...state,
          data: handleFeature(action.data),
          totalPages: action.totalPages,
          totalRecords: action.totalRecords,
          params: action.params,
          start: action.start,
          end: action.end,
        };
      case "GET_ALL_DATA":
        return {
          ...state,
        };
      case "GET_VEHICLE_MODEL_DETAILS":
        return {
          ...state,
          vehicle_model_detail: action.payload
        };

      case "GET_VEHICLE_MODEL_ID":
        return {
          ...state,
          id: action.id,
        };
      case "GET_VEHICLE_MAKES":
        return {
          ...state,
          makes: handleMakes(action.payload.makes),
        };
        case "GET_ACTIVE_VEHICLE_MODEL":
        return {
          ...state,
          active_vehicle_model:handleActiveModel(action.payload),
        };
      default:
        return state;
    }
  }
  
  export default DataListReducer
  