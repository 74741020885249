const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  start:"",
  end:"",
};
const arrangeData =(value)=>{
  const y =[]
  value.map(x=>{
    var count=0;
    var driver_licence=""
    var driver_practice_permit="";

x.documents.map(z=>{
  console.log("zzzzzzzzzzzzzz",z)
  if (z.file_type == "driver_licence") {
    driver_licence=z.file_path 
  }
  if (z.file_type == "driver_practice_permit") {
    driver_practice_permit=z.file_path 
  }
  count++
  if(count==2){
    y.push({
      _id:x._id,
      first_name:x.first_name,
      last_name:x.last_name,
      email:x.email,
      phone_number_format:x.phone_number_format,
      status:{
        working_status:x.status.working_status,
        account_status:x.status.account_status
      },
      created_on:x.created_on,
      approved_on:x.approved_on,
      profile_image:x.profile_image,
      rating:x.rating,
      driver_licence:driver_licence,
      driver_practice_permit:driver_practice_permit
      // plate_number: x.plate_number,
      // make:x.vehicle_model.make,
      // model:x.vehicle_model.model,
      // service_class:x.service_class.name,
      // vehicle_license:vehicle_license,
      // vehicle_insurance:vehicle_insurance
      // vehicle_insurance:x.z[0].file_path,
      // vehicle_licence:x.z[1].file_path
    })
  }

  

  // if (z.file_type == "vehicle_license") {
  //   vehicle_license=z.file_path 
  // }
  
})
  })
  return y
}




const PartnerVehicle = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PARTNER_DRIVER":
      return {
        ...state,
        data: arrangeData(action.data),
        // data:action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    default:
      return state;
  }
};

export default PartnerVehicle;
