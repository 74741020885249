import axios from "axios";
import {notification} from "../utility/notification"

export default {
  setupInterceptors: (store) => {
    // console.log("store",store.dispatch({type:"IN SETUP INTERCEPTORS"}))

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        console.log("from network-services", response.data);

        // if(response.data.status==0){
        //     notification("hello",response.data.message.toString(),"danger")
        // }
        return response;
      },
      function (error) {
        console.log(error.response.status)
        if(error.response.status==401){
          console.log("EXPIRED TOKEN!!!!!!!")
        }
        console.log("from network-services", error.response);


        //catches if the session ended!
        // if ( error.response.data.token.KEY == 'ERR_EXPIRED_TOKEN') {
        //     console.log("EXPIRED TOKEN!");
        //     localStorage.clear();
        //    // store.dispatch({ type: UNAUTH_USER });
        // }
        return Promise.reject(error);
      }
    );
  },
};
