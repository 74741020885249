import Resturl from "../../../configs/url";

import axios from "axios";
import {
  loadingTrue,
  loadingFalse,
  loadingSidebarTrue,
  loadingSidebarFalse,
} from "../loading";
import { checkSign } from "../../../utility/sign";
import { notification } from "../../../utility/notification";
import swal from "sweetalert";
import { history } from "../../../history";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, callback) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
    };
  }

  return async (dispatch) => {
    const signature = await checkSign(params);
    await axios
      .post(Resturl.service_class, params, {
        headers: {
          signature,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_SERVICE_CLASS",
          data: response.data.service_class,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_serviceclass,
          start: response.data.start,
          end: response.data.end,
          params,
        });
        dispatch({
          type: "SERVICE_CLASS_STATUS_LOADER",
          payload: { id: "", loading: false },
        });
        if (callback) callback();
      })
      .catch((err) => {
        if (callback) callback();
      });
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_DATA", value });
};

// export const filterData = (value) => {
//   let params = {
//     page: 1,
//     limit: 20,
//     search_data: value,
//   };
//   return async (dispatch) => {
//     await axios.post(Resturl.service_class, params).then((response) => {
//       dispatch({
//         type: 'GET_SERVICE_CLASS',
//         data: response.data.service_class,
//         totalPages: response.data.total_page,
//         totalRecords: response.data.total_serviceclass,
//         start: response.data.start,
//         end: response.data.end,
//         params,
//       });
//       dispatch({
//         type: "SERVICE_CLASS_STATUS_LOADER",
//         payload: { id: "", loading: false },
//       });
//     });
//   };
// };

export const deleteData = (id) => {
  console.log("id", Resturl);
  return (dispatch) => {
    axios
      .delete(Resturl.delete_service_class + "/" + id)
      .then((res) => {
        console.log("res", res);
        // swal("Deleted!", "Your service class has been deleted!", "success");
        dispatch(getData());
        if (res?.data?.status == 1) {
          notification("Service Class", res.data.message, "success");
          dispatch({ type: "DELETE_SERVICE_CLASS", payload: res });
          // history.push('/service-class')
        } else {
          notification("Service Class", res.data.message, "danger");
        }
        dispatch(getData());
      })
      .catch((err) => {
        console.log("err=", err);
        dispatch({ type: "DELETE_SERVICE_CLASS_ERROR", payload: err.response });
        //notification("Vehicle Model",err.response.message,"danger")
      });
  };
};

export const updateData = (id, obj, cb) => {
  return (dispatch, getState) => {
    const param = {
      name: obj.service_class,
      color: obj.color,
      max_person: obj.max_person,
      max_luggage: obj.max_luggage,
      features: obj.features,
      // color: getState().service_class.color,
      id: id,
    };
    axios.put(Resturl.update_service_class, param).then((response) => {
      if (response?.data?.status == 1) {
        if (cb) cb();
        notification("Service Class", response.data.message, "success");
        dispatch({ type: "UPDATE_SERVICE_CLASS", param });
        history.push("/service-class");
      } else {
        if (cb) cb();
        notification("Sub Admin", response.data.message, "danger");
      }
    });
  };
};

export const addData = (obj, cb) => {
  return (dispatch, getState) => {
    console.log("objobj123", obj);
    const param = {
      name: obj.service_class,
      color: obj.color,
      max_person: obj.max_person,
      max_luggage: obj.max_luggage,
      features: obj.features,
      // color: "#000000",
    };

    axios
      .post(Resturl.add_service_class, param)
      .then((response) => {
        if (cb) cb();
        if (response?.data?.status == 1) {
          notification("Service Class", response.data.message, "success");
          dispatch({ type: "ADD_SERVICE_CLASS", param });
          history.push("/service-class");
        } else {
          notification("Service Class", response.data.message, "danger");
        }
      })
      .catch((err) => {
        if (cb) cb();
      });
  };
};

export const getId = (id) => {
  return (dispatch) => {
    if (id == "") {
      dispatch({
        type: "GET_SERVICE_CLASS_DETAILS",
        payload: { features: [""] },
        color: "#000000",
      });
    } else {
      dispatch({
        type: "GET_SERVICE_CLASS_ID",
        id: id,
      });
    }
  };
};

export const getServiceClassDetail = (id) => {
  return async (dispatch, getState) => {
    dispatch(loadingSidebarTrue());
    await axios
      .get(`${Resturl.service_class_detail}/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_SERVICE_CLASS_DETAILS",
          payload: response.data.service_class,
          color: response.data.service_class.color,
        });
        dispatch(loadingSidebarFalse());
      })
      .catch((error) => {
        dispatch(loadingSidebarFalse());
      });
  };
};

export const getServiceDetail = () => {
  return async (dispatch, getState) => {
    dispatch(loadingSidebarTrue());
    await axios
      .get(`${Resturl.service_class_detail}/${getState().service_class.id}`)
      .then((response) => {
        dispatch({
          type: "GET_SERVICE_CLASS_DETAILS",
          payload: response.data.service_class,
          color: response.data.service_class.color,
        });
        dispatch(loadingSidebarFalse());
      })
      .catch((error) => {
        dispatch(loadingSidebarFalse());
      });
  };
};

export const changeColor = (color) => {
  return async (dispatch, getState) => {
    if (getState().service_class.id == "") {
      dispatch({
        type: "CHANGE_COLOR",
        payload: "#000000",
      });
    } else {
      dispatch({
        type: "CHANGE_COLOR",
        payload: color,
      });
    }

    // dispatch(loadingSidebarTrue())
    // await axios.get(`${Resturl.service_class_detail}/${getState().service_class.id}`).then((response) => {
    //   dispatch({
    //     type: "GET_SERVICE_CLASS_DETAILS",
    //     payload:response.data.service_class
    //   });
    //   dispatch(loadingSidebarFalse());
    // }).catch(error=>{

    //   dispatch(loadingSidebarFalse());
    // });
  };
};

export const changeStatus = (obj) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SERVICE_CLASS_STATUS_LOADER",
      payload: { id: obj.id, loading: true },
    });
    if (obj.status == "DEACTIVATED") {
      const param = {
        id: obj.id,
        status: "ACTIVE",
      };
      axios.put(Resturl.update_service_class, param).then((response) => {
        dispatch({ type: "CHANGE_SERVICE_CLASS_STATUS", param });
        dispatch(getData(getState().service_class.params));
      });
    } else {
      const param = {
        id: obj.id,
        status: "DEACTIVATED",
      };
      axios.put(Resturl.update_service_class, param).then((response) => {
        dispatch({ type: "CHANGE_SERVICE_CLASS_STATUS", param });
        dispatch(getData(getState().service_class.params));
      });
    }
  };
};

export const activeServiceClass = () => {
  return function (dispatch, getState) {
    axios.get(Resturl.active_service_class).then((response) => {
      dispatch({
        type: "GET_ACTIVE_SERVICE_CLASS",
        payload: response.data.service_class,
      });
    });
  };
};
