import { Country, State, City } from "country-state-city";

const initialState = {
  data: [
    {
      latlng: [-12.5, 18.5],
      timezones: ["Africa/Luanda"],
      _id: "5eabb192573ad310d849a5e4",
      name: "Angola",
      country_code: "AO",
      capital: "Luanda",
      dial_code: 244,
      is_service_provide: false,
    },
    {
      latlng: [18.25, -63.16666666],
      timezones: ["America/Anguilla"],
      _id: "5eabb192573ad310d849a5e5",
      name: "Anguilla",
      country_code: "AI",
      capital: "The Valley",
      is_service_provide: false,
    },
    {
      latlng: [60.116667, 19.9],
      timezones: ["Europe/Mariehamn"],
      _id: "5eabb192573ad310d849a5e6",
      name: "Åland Islands",
      country_code: "AX",
      capital: "Mariehamn",
      dial_code: 358,
      is_service_provide: false,
    },
    {
      latlng: [41, 20],
      timezones: ["Europe/Tirane"],
      _id: "5eabb192573ad310d849a5e7",
      name: "Albania",
      country_code: "AL",
      capital: "Tirana",
      dial_code: 355,
      is_service_provide: false,
    },
    {
      latlng: [42.5, 1.5],
      timezones: ["Europe/Andorra"],
      _id: "5eabb192573ad310d849a5e8",
      name: "Andorra",
      country_code: "AD",
      capital: "Andorra la Vella",
      dial_code: 376,
      is_service_provide: false,
    },
    {
      latlng: [24, 54],
      timezones: ["Asia/Dubai"],
      _id: "5eabb192573ad310d849a5e9",
      name: "United Arab Emirates",
      country_code: "AE",
      capital: "Abu Dhabi",
      dial_code: 971,
      is_service_provide: true,
    },
    {
      latlng: [-34, -64],
      timezones: [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Cordoba",
        "America/Argentina/Salta",
        "America/Argentina/Jujuy",
        "America/Argentina/Tucuman",
        "America/Argentina/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Argentina/San_Juan",
        "America/Argentina/Mendoza",
        "America/Argentina/San_Luis",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Ushuaia",
      ],
      _id: "5eabb192573ad310d849a5ea",
      name: "Argentina",
      country_code: "AR",
      capital: "Buenos Aires",
      dial_code: 54,
      is_service_provide: false,
    },
    {
      latlng: [40, 45],
      timezones: ["Asia/Yerevan"],
      _id: "5eabb192573ad310d849a5eb",
      name: "Armenia",
      country_code: "AM",
      capital: "Yerevan",
      dial_code: 374,
      is_service_provide: false,
    },
    {
      latlng: [-14.33333333, -170],
      timezones: ["Pacific/Pago_Pago"],
      _id: "5eabb192573ad310d849a5ec",
      name: "American Samoa",
      country_code: "AS",
      capital: "Pago Pago",
      is_service_provide: false,
    },
    {
      latlng: [-90, 0],
      timezones: [
        "Antarctica/McMurdo",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Mawson",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
      ],
      _id: "5eabb192573ad310d849a5ed",
      name: "Antarctica",
      country_code: "AQ",
      capital: null,
      dial_code: 672,
      is_service_provide: false,
    },
    {
      latlng: [17.05, -61.8],
      timezones: ["America/Antigua"],
      _id: "5eabb192573ad310d849a5ee",
      name: "Antigua and Barbuda",
      country_code: "AG",
      capital: "Saint John's",
      is_service_provide: false,
    },
    {
      latlng: [-27, 133],
      timezones: [
        "Australia/Lord_Howe",
        "Antarctica/Macquarie",
        "Australia/Hobart",
        "Australia/Currie",
        "Australia/Melbourne",
        "Australia/Sydney",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Lindeman",
        "Australia/Adelaide",
        "Australia/Darwin",
        "Australia/Perth",
        "Australia/Eucla",
      ],
      _id: "5eabb192573ad310d849a5ef",
      name: "Australia",
      country_code: "AU",
      capital: "Canberra",
      dial_code: 61,
      is_service_provide: false,
    },
    {
      latlng: [47.33333333, 13.33333333],
      timezones: ["Europe/Vienna"],
      _id: "5eabb192573ad310d849a5f0",
      name: "Austria",
      country_code: "AT",
      capital: "Vienna",
      dial_code: 43,
      is_service_provide: false,
    },
    {
      latlng: [40.5, 47.5],
      timezones: ["Asia/Baku"],
      _id: "5eabb192573ad310d849a5f1",
      name: "Azerbaijan",
      country_code: "AZ",
      capital: "Baku",
      dial_code: 994,
      is_service_provide: false,
    },
    {
      latlng: [-3.5, 30],
      timezones: ["Africa/Bujumbura"],
      _id: "5eabb192573ad310d849a5f2",
      name: "Burundi",
      country_code: "BI",
      capital: "Bujumbura",
      dial_code: 257,
      is_service_provide: false,
    },
    {
      latlng: [50.83333333, 4],
      timezones: ["Europe/Brussels"],
      _id: "5eabb192573ad310d849a5f3",
      name: "Belgium",
      country_code: "BE",
      capital: "Brussels",
      dial_code: 32,
      is_service_provide: false,
    },
    {
      latlng: [9.5, 2.25],
      timezones: ["Africa/Porto-Novo"],
      _id: "5eabb192573ad310d849a5f4",
      name: "Benin",
      country_code: "BJ",
      capital: "Porto-Novo",
      dial_code: 229,
      is_service_provide: false,
    },
    {
      latlng: [13, -2],
      timezones: ["Africa/Ouagadougou"],
      _id: "5eabb192573ad310d849a5f5",
      name: "Burkina Faso",
      country_code: "BF",
      capital: "Ouagadougou",
      dial_code: 226,
      is_service_provide: false,
    },
    {
      latlng: [24, 90],
      timezones: ["Asia/Dhaka"],
      _id: "5eabb192573ad310d849a5f6",
      name: "Bangladesh",
      country_code: "BD",
      capital: "Dhaka",
      dial_code: 880,
      is_service_provide: false,
    },
    {
      latlng: [43, 25],
      timezones: ["Europe/Sofia"],
      _id: "5eabb192573ad310d849a5f7",
      name: "Bulgaria",
      country_code: "BG",
      capital: "Sofia",
      dial_code: 359,
      is_service_provide: false,
    },
    {
      latlng: [26, 50.55],
      timezones: ["Asia/Bahrain"],
      _id: "5eabb192573ad310d849a5f8",
      name: "Bahrain",
      country_code: "BH",
      capital: "Manama",
      dial_code: 973,
      is_service_provide: false,
    },
    {
      latlng: [24.25, -76],
      timezones: ["America/Nassau"],
      _id: "5eabb192573ad310d849a5f9",
      name: "Bahamas",
      country_code: "BS",
      capital: "Nassau",
      is_service_provide: false,
    },
    {
      latlng: [44, 18],
      timezones: ["Europe/Sarajevo"],
      _id: "5eabb192573ad310d849a5fa",
      name: "Bosnia and Herzegovina",
      country_code: "BA",
      capital: "Sarajevo",
      dial_code: 387,
      is_service_provide: false,
    },
    {
      latlng: [53, 28],
      timezones: ["Europe/Minsk"],
      _id: "5eabb192573ad310d849a5fb",
      name: "Belarus",
      country_code: "BY",
      capital: "Minsk",
      dial_code: 375,
      is_service_provide: false,
    },
    {
      latlng: [17.25, -88.75],
      timezones: ["America/Belize"],
      _id: "5eabb192573ad310d849a5fc",
      name: "Belize",
      country_code: "BZ",
      capital: "Belmopan",
      dial_code: 501,
      is_service_provide: false,
    },
    {
      latlng: [32.33333333, -64.75],
      timezones: ["Atlantic/Bermuda"],
      _id: "5eabb192573ad310d849a5fd",
      name: "Bermuda",
      country_code: "BM",
      capital: "Hamilton",
      is_service_provide: false,
    },
    {
      latlng: [-17, -65],
      timezones: ["America/La_Paz"],
      _id: "5eabb192573ad310d849a5fe",
      name: "Bolivia",
      country_code: "BO",
      capital: "Sucre",
      dial_code: 591,
      is_service_provide: false,
    },
    {
      latlng: [-10, -55],
      timezones: [
        "America/Noronha",
        "America/Belem",
        "America/Fortaleza",
        "America/Recife",
        "America/Araguaina",
        "America/Maceio",
        "America/Bahia",
        "America/Sao_Paulo",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Santarem",
        "America/Porto_Velho",
        "America/Boa_Vista",
        "America/Manaus",
        "America/Eirunepe",
        "America/Rio_Branco",
      ],
      _id: "5eabb192573ad310d849a5ff",
      name: "Brazil",
      country_code: "BR",
      capital: "Brasília",
      dial_code: 55,
      is_service_provide: false,
    },
    {
      latlng: [13.16666666, -59.53333333],
      timezones: ["America/Barbados"],
      _id: "5eabb192573ad310d849a600",
      name: "Barbados",
      country_code: "BB",
      capital: "Bridgetown",
      is_service_provide: false,
    },
    {
      latlng: [4.5, 114.66666666],
      timezones: ["Asia/Brunei"],
      _id: "5eabb192573ad310d849a601",
      name: "Brunei",
      country_code: "BN",
      capital: "Bandar Seri Begawan",
      dial_code: 673,
      is_service_provide: false,
    },
    {
      latlng: [27.5, 90.5],
      timezones: ["Asia/Thimphu"],
      _id: "5eabb192573ad310d849a602",
      name: "Bhutan",
      country_code: "BT",
      capital: "Thimphu",
      dial_code: 975,
      is_service_provide: false,
    },
    {
      latlng: [-54.43333333, 3.4],
      timezones: ["Europe/Oslo"],
      _id: "5eabb192573ad310d849a603",
      name: "Bouvet Island",
      country_code: "BV",
      capital: null,
      dial_code: 470,
      is_service_provide: false,
    },
    {
      latlng: [-22, 24],
      timezones: ["Africa/Gaborone"],
      _id: "5eabb192573ad310d849a604",
      name: "Botswana",
      country_code: "BW",
      capital: "Gaborone",
      dial_code: 267,
      is_service_provide: false,
    },
    {
      latlng: [7, 21],
      timezones: ["Africa/Bangui"],
      _id: "5eabb192573ad310d849a605",
      name: "Central African Republic",
      country_code: "CF",
      capital: "Bangui",
      dial_code: 236,
      is_service_provide: false,
    },
    {
      latlng: [60, -95],
      timezones: [
        "America/St_Johns",
        "America/Halifax",
        "America/Glace_Bay",
        "America/Moncton",
        "America/Goose_Bay",
        "America/Blanc-Sablon",
        "America/Toronto",
        "America/Nipigon",
        "America/Thunder_Bay",
        "America/Iqaluit",
        "America/Pangnirtung",
        "America/Atikokan",
        "America/Winnipeg",
        "America/Rainy_River",
        "America/Resolute",
        "America/Rankin_Inlet",
        "America/Regina",
        "America/Swift_Current",
        "America/Edmonton",
        "America/Cambridge_Bay",
        "America/Yellowknife",
        "America/Inuvik",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Fort_Nelson",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Dawson",
      ],
      _id: "5eabb192573ad310d849a606",
      name: "Canada",
      country_code: "CA",
      capital: "Ottawa",
      dial_code: 1,
      is_service_provide: false,
    },
    {
      latlng: [-12.5, 96.83333333],
      timezones: ["Indian/Cocos"],
      _id: "5eabb192573ad310d849a607",
      name: "Cocos (Keeling) Islands",
      country_code: "CC",
      capital: "West Island",
      dial_code: 61,
      is_service_provide: false,
    },
    {
      latlng: [47, 8],
      timezones: ["Europe/Zurich"],
      _id: "5eabb192573ad310d849a608",
      name: "Switzerland",
      country_code: "CH",
      capital: "Bern",
      dial_code: 41,
      is_service_provide: false,
    },
    {
      latlng: [-30, -71],
      timezones: ["America/Santiago", "Pacific/Easter"],
      _id: "5eabb192573ad310d849a609",
      name: "Chile",
      country_code: "CL",
      capital: "Santiago",
      dial_code: 56,
      is_service_provide: false,
    },
    {
      latlng: [35, 105],
      timezones: ["Asia/Shanghai", "Asia/Urumqi"],
      _id: "5eabb192573ad310d849a60a",
      name: "China",
      country_code: "CN",
      capital: "Beijing",
      dial_code: 86,
      is_service_provide: false,
    },
    {
      latlng: [8, -5],
      timezones: ["Africa/Abidjan"],
      _id: "5eabb192573ad310d849a60b",
      name: "Ivory Coast",
      country_code: "CI",
      capital: "Yamoussoukro",
      dial_code: 225,
      is_service_provide: false,
    },
    {
      latlng: [6, 12],
      timezones: ["Africa/Douala"],
      _id: "5eabb192573ad310d849a60c",
      name: "Cameroon",
      country_code: "CM",
      capital: "Yaoundé",
      dial_code: 237,
      is_service_provide: false,
    },
    {
      latlng: [-1, 15],
      timezones: ["Africa/Brazzaville"],
      _id: "5eabb192573ad310d849a60d",
      name: "Republic of the Congo",
      country_code: "CG",
      capital: "Brazzaville",
      dial_code: 242,
      is_service_provide: false,
    },
    {
      latlng: [-21.23333333, -159.76666666],
      timezones: ["Pacific/Rarotonga"],
      _id: "5eabb192573ad310d849a60e",
      name: "Cook Islands",
      country_code: "CK",
      capital: "Avarua",
      dial_code: 682,
      is_service_provide: false,
    },
    {
      latlng: [4, -72],
      timezones: ["America/Bogota"],
      _id: "5eabb192573ad310d849a60f",
      name: "Colombia",
      country_code: "CO",
      capital: "Bogotá",
      dial_code: 57,
      is_service_provide: false,
    },
    {
      latlng: [-12.16666666, 44.25],
      timezones: ["Indian/Comoro"],
      _id: "5eabb192573ad310d849a610",
      name: "Comoros",
      country_code: "KM",
      capital: "Moroni",
      dial_code: 269,
      is_service_provide: false,
    },
    {
      latlng: [16, -24],
      timezones: ["Atlantic/Cape_Verde"],
      _id: "5eabb192573ad310d849a611",
      name: "Cape Verde",
      country_code: "CV",
      capital: "Praia",
      dial_code: 238,
      is_service_provide: false,
    },
    {
      latlng: [10, -84],
      timezones: ["America/Costa_Rica"],
      _id: "5eabb192573ad310d849a612",
      name: "Costa Rica",
      country_code: "CR",
      capital: "San José",
      dial_code: 506,
      is_service_provide: false,
    },
    {
      latlng: [21.5, -80],
      timezones: ["America/Havana"],
      _id: "5eabb192573ad310d849a613",
      name: "Cuba",
      country_code: "CU",
      capital: "Havana",
      dial_code: 53,
      is_service_provide: false,
    },
    {
      latlng: [12.116667, -68.933333],
      timezones: ["America/Curacao"],
      _id: "5eabb192573ad310d849a614",
      name: "Curaçao",
      country_code: "CW",
      capital: "Willemstad",
      dial_code: 599,
      is_service_provide: false,
    },
    {
      latlng: [-10.5, 105.66666666],
      timezones: ["Indian/Christmas"],
      _id: "5eabb192573ad310d849a615",
      name: "Christmas Island",
      country_code: "CX",
      capital: "Flying Fish Cove",
      dial_code: 61,
      is_service_provide: false,
    },
    {
      latlng: [19.5, -80.5],
      timezones: ["America/Cayman"],
      _id: "5eabb192573ad310d849a616",
      name: "Cayman Islands",
      country_code: "KY",
      capital: "George Town",
      is_service_provide: false,
    },
    {
      latlng: [35, 33],
      timezones: ["Asia/Nicosia"],
      _id: "5eabb192573ad310d849a617",
      name: "Cyprus",
      country_code: "CY",
      capital: "Nicosia",
      dial_code: 357,
      is_service_provide: false,
    },
    {
      latlng: [49.75, 15.5],
      timezones: ["Europe/Prague"],
      _id: "5eabb192573ad310d849a618",
      name: "Czech Republic",
      country_code: "CZ",
      capital: "Prague",
      dial_code: 420,
      is_service_provide: false,
    },
    {
      latlng: [51, 9],
      timezones: ["Europe/Berlin", "Europe/Busingen"],
      _id: "5eabb192573ad310d849a619",
      name: "Germany",
      country_code: "DE",
      capital: "Berlin",
      dial_code: 49,
      is_service_provide: false,
    },
    {
      latlng: [11.5, 43],
      timezones: ["Africa/Djibouti"],
      _id: "5eabb192573ad310d849a61a",
      name: "Djibouti",
      country_code: "DJ",
      capital: "Djibouti",
      dial_code: 253,
      is_service_provide: false,
    },
    {
      latlng: [15.41666666, -61.33333333],
      timezones: ["America/Dominica"],
      _id: "5eabb192573ad310d849a61b",
      name: "Dominica",
      country_code: "DM",
      capital: "Roseau",
      is_service_provide: false,
    },
    {
      latlng: [56, 10],
      timezones: ["Europe/Copenhagen"],
      _id: "5eabb192573ad310d849a61c",
      name: "Denmark",
      country_code: "DK",
      capital: "Copenhagen",
      dial_code: 45,
      is_service_provide: false,
    },
    {
      latlng: [19, -70.66666666],
      timezones: ["America/Santo_Domingo"],
      _id: "5eabb192573ad310d849a61d",
      name: "Dominican Republic",
      country_code: "DO",
      capital: "Santo Domingo",
      is_service_provide: false,
    },
    {
      latlng: [28, 3],
      timezones: ["Africa/Algiers"],
      _id: "5eabb192573ad310d849a61e",
      name: "Algeria",
      country_code: "DZ",
      capital: "Algiers",
      dial_code: 213,
      is_service_provide: false,
    },
    {
      latlng: [-2, -77.5],
      timezones: ["America/Guayaquil", "Pacific/Galapagos"],
      _id: "5eabb192573ad310d849a61f",
      name: "Ecuador",
      country_code: "EC",
      capital: "Quito",
      dial_code: 593,
      is_service_provide: false,
    },
    {
      latlng: [27, 30],
      timezones: ["Africa/Cairo"],
      _id: "5eabb192573ad310d849a620",
      name: "Egypt",
      country_code: "EG",
      capital: "Cairo",
      dial_code: 20,
      is_service_provide: false,
    },
    {
      latlng: [15, 39],
      timezones: ["Africa/Asmara"],
      _id: "5eabb192573ad310d849a621",
      name: "Eritrea",
      country_code: "ER",
      capital: "Asmara",
      dial_code: 291,
      is_service_provide: false,
    },
    {
      latlng: [24.5, -13],
      timezones: ["Africa/El_Aaiun"],
      _id: "5eabb192573ad310d849a622",
      name: "Western Sahara",
      country_code: "EH",
      capital: "El Aaiún",
      dial_code: 212,
      is_service_provide: false,
    },
    {
      latlng: [40, -4],
      timezones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
      _id: "5eabb192573ad310d849a623",
      name: "Spain",
      country_code: "ES",
      capital: "Madrid",
      dial_code: 34,
      is_service_provide: false,
    },
    {
      latlng: [59, 26],
      timezones: ["Europe/Tallinn"],
      _id: "5eabb192573ad310d849a624",
      name: "Estonia",
      country_code: "EE",
      capital: "Tallinn",
      dial_code: 372,
      is_service_provide: false,
    },
    {
      latlng: [8, 38],
      timezones: ["Africa/Addis_Ababa"],
      _id: "5eabb192573ad310d849a625",
      name: "Ethiopia",
      country_code: "ET",
      capital: "Addis Ababa",
      dial_code: 251,
      is_service_provide: false,
    },
    {
      latlng: [64, 26],
      timezones: ["Europe/Helsinki"],
      _id: "5eabb192573ad310d849a626",
      name: "Finland",
      country_code: "FI",
      capital: "Helsinki",
      dial_code: 358,
      is_service_provide: false,
    },
    {
      latlng: [-18, 175],
      timezones: ["Pacific/Fiji"],
      _id: "5eabb192573ad310d849a627",
      name: "Fiji",
      country_code: "FJ",
      capital: "Suva",
      dial_code: 679,
      is_service_provide: false,
    },
    {
      latlng: [-51.75, -59],
      timezones: ["Atlantic/Stanley"],
      _id: "5eabb192573ad310d849a628",
      name: "Falkland Islands",
      country_code: "FK",
      capital: "Stanley",
      dial_code: 500,
      is_service_provide: false,
    },
    {
      latlng: [46, 2],
      timezones: ["Europe/Paris"],
      _id: "5eabb192573ad310d849a629",
      name: "France",
      country_code: "FR",
      capital: "Paris",
      dial_code: 33,
      is_service_provide: false,
    },
    {
      latlng: [62, -7],
      timezones: ["Atlantic/Faroe"],
      _id: "5eabb192573ad310d849a62a",
      name: "Faroe Islands",
      country_code: "FO",
      capital: "Tórshavn",
      dial_code: 298,
      is_service_provide: false,
    },
    {
      latlng: [6.91666666, 158.25],
      timezones: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
      _id: "5eabb192573ad310d849a62b",
      name: "Micronesia",
      country_code: "FM",
      capital: "Palikir",
      dial_code: 691,
      is_service_provide: false,
    },
    {
      latlng: [-1, 11.75],
      timezones: ["Africa/Libreville"],
      _id: "5eabb192573ad310d849a62c",
      name: "Gabon",
      country_code: "GA",
      capital: "Libreville",
      dial_code: 241,
      is_service_provide: false,
    },
    {
      latlng: [54, -2],
      timezones: ["Europe/London"],
      _id: "5eabb192573ad310d849a62d",
      name: "United Kingdom",
      country_code: "GB",
      capital: "London",
      dial_code: 44,
      is_service_provide: false,
    },
    {
      latlng: [42, 43.5],
      timezones: ["Asia/Tbilisi"],
      _id: "5eabb192573ad310d849a62e",
      name: "Georgia",
      country_code: "GE",
      capital: "Tbilisi",
      dial_code: 995,
      is_service_provide: false,
    },
    {
      latlng: [49.46666666, -2.58333333],
      timezones: ["Europe/Guernsey"],
      _id: "5eabb192573ad310d849a62f",
      name: "Guernsey",
      country_code: "GG",
      capital: "St. Peter Port",
      is_service_provide: false,
    },
    {
      latlng: [8, -2],
      timezones: ["Africa/Accra"],
      _id: "5eabb192573ad310d849a630",
      name: "Ghana",
      country_code: "GH",
      capital: "Accra",
      dial_code: 233,
      is_service_provide: false,
    },
    {
      latlng: [36.13333333, -5.35],
      timezones: ["Europe/Gibraltar"],
      _id: "5eabb192573ad310d849a631",
      name: "Gibraltar",
      country_code: "GI",
      capital: "Gibraltar",
      dial_code: 350,
      is_service_provide: false,
    },
    {
      latlng: [11, -10],
      timezones: ["Africa/Conakry"],
      _id: "5eabb192573ad310d849a632",
      name: "Guinea",
      country_code: "GN",
      capital: "Conakry",
      dial_code: 224,
      is_service_provide: false,
    },
    {
      latlng: [16.25, -61.583333],
      timezones: ["America/Guadeloupe"],
      _id: "5eabb192573ad310d849a633",
      name: "Guadeloupe",
      country_code: "GP",
      capital: "Basse-Terre",
      dial_code: 590,
      is_service_provide: false,
    },
    {
      latlng: [13.46666666, -16.56666666],
      timezones: ["Africa/Banjul"],
      _id: "5eabb192573ad310d849a634",
      name: "Gambia",
      country_code: "GM",
      capital: "Banjul",
      dial_code: 220,
      is_service_provide: false,
    },
    {
      latlng: [12, -15],
      timezones: ["Africa/Bissau"],
      _id: "5eabb192573ad310d849a635",
      name: "Guinea-Bissau",
      country_code: "GW",
      capital: "Bissau",
      dial_code: 245,
      is_service_provide: false,
    },
    {
      latlng: [2, 10],
      timezones: ["Africa/Malabo"],
      _id: "5eabb192573ad310d849a636",
      name: "Equatorial Guinea",
      country_code: "GQ",
      capital: "Malabo",
      dial_code: 240,
      is_service_provide: false,
    },
    {
      latlng: [39, 22],
      timezones: ["Europe/Athens"],
      _id: "5eabb192573ad310d849a637",
      name: "Greece",
      country_code: "GR",
      capital: "Athens",
      dial_code: 30,
      is_service_provide: false,
    },
    {
      latlng: [12.11666666, -61.66666666],
      timezones: ["America/Grenada"],
      _id: "5eabb192573ad310d849a638",
      name: "Grenada",
      country_code: "GD",
      capital: "St. George's",
      is_service_provide: false,
    },
    {
      latlng: [72, -40],
      timezones: [
        "America/Godthab",
        "America/Danmarkshavn",
        "America/Scoresbysund",
        "America/Thule",
      ],
      _id: "5eabb192573ad310d849a639",
      name: "Greenland",
      country_code: "GL",
      capital: "Nuuk",
      dial_code: 299,
      is_service_provide: false,
    },
    {
      latlng: [15.5, -90.25],
      timezones: ["America/Guatemala"],
      _id: "5eabb192573ad310d849a63a",
      name: "Guatemala",
      country_code: "GT",
      capital: "Guatemala City",
      dial_code: 502,
      is_service_provide: false,
    },
    {
      latlng: [4, -53],
      timezones: ["America/Cayenne"],
      _id: "5eabb192573ad310d849a63b",
      name: "French Guiana",
      country_code: "GF",
      capital: "Cayenne",
      dial_code: 594,
      is_service_provide: false,
    },
    {
      latlng: [13.46666666, 144.78333333],
      timezones: ["Pacific/Guam"],
      _id: "5eabb192573ad310d849a63c",
      name: "Guam",
      country_code: "GU",
      capital: "Hagåtña",
      is_service_provide: false,
    },
    {
      latlng: [5, -59],
      timezones: ["America/Guyana"],
      _id: "5eabb192573ad310d849a63d",
      name: "Guyana",
      country_code: "GY",
      capital: "Georgetown",
      dial_code: 592,
      is_service_provide: false,
    },
    {
      latlng: [22.267, 114.188],
      timezones: ["Asia/Hong_Kong"],
      _id: "5eabb192573ad310d849a63e",
      name: "Hong Kong",
      country_code: "HK",
      capital: "City of Victoria",
      dial_code: 852,
      is_service_provide: false,
    },
    {
      latlng: [15, -86.5],
      timezones: ["America/Tegucigalpa"],
      _id: "5eabb192573ad310d849a63f",
      name: "Honduras",
      country_code: "HN",
      capital: "Tegucigalpa",
      dial_code: 504,
      is_service_provide: false,
    },
    {
      latlng: [45.16666666, 15.5],
      timezones: ["Europe/Zagreb"],
      _id: "5eabb192573ad310d849a640",
      name: "Croatia",
      country_code: "HR",
      capital: "Zagreb",
      dial_code: 385,
      is_service_provide: false,
    },
    {
      latlng: [19, -72.41666666],
      timezones: ["America/Port-au-Prince"],
      _id: "5eabb192573ad310d849a641",
      name: "Haiti",
      country_code: "HT",
      capital: "Port-au-Prince",
      dial_code: 509,
      is_service_provide: false,
    },
    {
      latlng: [47, 20],
      timezones: ["Europe/Budapest"],
      _id: "5eabb192573ad310d849a642",
      name: "Hungary",
      country_code: "HU",
      capital: "Budapest",
      dial_code: 36,
      is_service_provide: false,
    },
    {
      latlng: [-5, 120],
      timezones: [
        "Asia/Jakarta",
        "Asia/Pontianak",
        "Asia/Makassar",
        "Asia/Jayapura",
      ],
      _id: "5eabb192573ad310d849a643",
      name: "Indonesia",
      country_code: "ID",
      capital: "Jakarta",
      dial_code: 62,
      is_service_provide: false,
    },
    {
      latlng: [54.25, -4.5],
      timezones: ["Europe/Isle_of_Man"],
      _id: "5eabb192573ad310d849a644",
      name: "Isle of Man",
      country_code: "IM",
      capital: "Douglas",
      is_service_provide: false,
    },
    {
      latlng: [20, 77],
      timezones: ["Asia/Kolkata"],
      _id: "5eabb192573ad310d849a645",
      name: "India",
      country_code: "IN",
      capital: "New Delhi",
      dial_code: 91,
      is_service_provide: true,
    },
    {
      latlng: [-6, 71.5],
      timezones: ["Indian/Chagos"],
      _id: "5eabb192573ad310d849a646",
      name: "British Indian Ocean Territory",
      country_code: "IO",
      capital: "Diego Garcia",
      dial_code: 246,
      is_service_provide: false,
    },
    {
      latlng: [53, -8],
      timezones: ["Europe/Dublin"],
      _id: "5eabb192573ad310d849a647",
      name: "Ireland",
      country_code: "IE",
      capital: "Dublin",
      dial_code: 353,
      is_service_provide: false,
    },
    {
      latlng: [32, 53],
      timezones: ["Asia/Tehran"],
      _id: "5eabb192573ad310d849a648",
      name: "Iran",
      country_code: "IR",
      capital: "Tehran",
      dial_code: 98,
      is_service_provide: false,
    },
    {
      latlng: [33, 44],
      timezones: ["Asia/Baghdad"],
      _id: "5eabb192573ad310d849a649",
      name: "Iraq",
      country_code: "IQ",
      capital: "Baghdad",
      dial_code: 964,
      is_service_provide: false,
    },
    {
      latlng: [65, -18],
      timezones: ["Atlantic/Reykjavik"],
      _id: "5eabb192573ad310d849a64a",
      name: "Iceland",
      country_code: "IS",
      capital: "Reykjavik",
      dial_code: 354,
      is_service_provide: false,
    },
    {
      latlng: [31.47, 35.13],
      timezones: ["Asia/Jerusalem"],
      _id: "5eabb192573ad310d849a64b",
      name: "Israel",
      country_code: "IL",
      capital: "Jerusalem",
      dial_code: 972,
      is_service_provide: false,
    },
    {
      latlng: [42.83333333, 12.83333333],
      timezones: ["Europe/Rome"],
      _id: "5eabb192573ad310d849a64c",
      name: "Italy",
      country_code: "IT",
      capital: "Rome",
      dial_code: 39,
      is_service_provide: false,
    },
    {
      latlng: [18.25, -77.5],
      timezones: ["America/Jamaica"],
      _id: "5eabb192573ad310d849a64d",
      name: "Jamaica",
      country_code: "JM",
      capital: "Kingston",
      is_service_provide: false,
    },
    {
      latlng: [49.25, -2.16666666],
      timezones: ["Europe/Jersey"],
      _id: "5eabb192573ad310d849a64e",
      name: "Jersey",
      country_code: "JE",
      capital: "Saint Helier",
      is_service_provide: false,
    },
    {
      latlng: [31, 36],
      timezones: ["Asia/Amman"],
      _id: "5eabb192573ad310d849a64f",
      name: "Jordan",
      country_code: "JO",
      capital: "Amman",
      dial_code: 962,
      is_service_provide: false,
    },
    {
      latlng: [36, 138],
      timezones: ["Asia/Tokyo"],
      _id: "5eabb192573ad310d849a650",
      name: "Japan",
      country_code: "JP",
      capital: "Tokyo",
      dial_code: 81,
      is_service_provide: false,
    },
    {
      latlng: [48, 68],
      timezones: [
        "Asia/Almaty",
        "Asia/Qyzylorda",
        "Asia/Aqtobe",
        "Asia/Aqtau",
        "Asia/Oral",
      ],
      _id: "5eabb192573ad310d849a651",
      name: "Kazakhstan",
      country_code: "KZ",
      capital: "Astana",
      dial_code: 7,
      is_service_provide: false,
    },
    {
      latlng: [1, 38],
      timezones: ["Africa/Nairobi"],
      _id: "5eabb192573ad310d849a652",
      name: "Kenya",
      country_code: "KE",
      capital: "Nairobi",
      dial_code: 254,
      is_service_provide: false,
    },
    {
      latlng: [41, 75],
      timezones: ["Asia/Bishkek"],
      _id: "5eabb192573ad310d849a653",
      name: "Kyrgyzstan",
      country_code: "KG",
      capital: "Bishkek",
      dial_code: 996,
      is_service_provide: false,
    },
    {
      latlng: [13, 105],
      timezones: ["Asia/Phnom_Penh"],
      _id: "5eabb192573ad310d849a654",
      name: "Cambodia",
      country_code: "KH",
      capital: "Phnom Penh",
      dial_code: 855,
      is_service_provide: false,
    },
    {
      latlng: [1.41666666, 173],
      timezones: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
      _id: "5eabb192573ad310d849a655",
      name: "Kiribati",
      country_code: "KI",
      capital: "South Tarawa",
      dial_code: 686,
      is_service_provide: false,
    },
    {
      latlng: [17.33333333, -62.75],
      timezones: ["America/St_Kitts"],
      _id: "5eabb192573ad310d849a656",
      name: "Saint Kitts and Nevis",
      country_code: "KN",
      capital: "Basseterre",
      is_service_provide: false,
    },
    {
      latlng: [37, 127.5],
      timezones: ["Asia/Seoul"],
      _id: "5eabb192573ad310d849a657",
      name: "South Korea",
      country_code: "KR",
      capital: "Seoul",
      dial_code: 82,
      is_service_provide: false,
    },
    {
      latlng: [42.666667, 21.166667],
      timezones: ["Europe/Belgrade"],
      _id: "5eabb192573ad310d849a658",
      name: "Kosovo",
      country_code: "XK",
      capital: "Pristina",
      dial_code: 383,
      is_service_provide: false,
    },
    {
      latlng: [29.5, 45.75],
      timezones: ["Asia/Kuwait"],
      _id: "5eabb192573ad310d849a659",
      name: "Kuwait",
      country_code: "KW",
      capital: "Kuwait City",
      dial_code: 965,
      is_service_provide: false,
    },
    {
      latlng: [18, 105],
      timezones: ["Asia/Vientiane"],
      _id: "5eabb192573ad310d849a65a",
      name: "Laos",
      country_code: "LA",
      capital: "Vientiane",
      dial_code: 856,
      is_service_provide: false,
    },
    {
      latlng: [33.83333333, 35.83333333],
      timezones: ["Asia/Beirut"],
      _id: "5eabb192573ad310d849a65b",
      name: "Lebanon",
      country_code: "LB",
      capital: "Beirut",
      dial_code: 961,
      is_service_provide: false,
    },
    {
      latlng: [6.5, -9.5],
      timezones: ["Africa/Monrovia"],
      _id: "5eabb192573ad310d849a65c",
      name: "Liberia",
      country_code: "LR",
      capital: "Monrovia",
      dial_code: 231,
      is_service_provide: false,
    },
    {
      latlng: [25, 17],
      timezones: ["Africa/Tripoli"],
      _id: "5eabb192573ad310d849a65d",
      name: "Libya",
      country_code: "LY",
      capital: "Tripoli",
      dial_code: 218,
      is_service_provide: false,
    },
    {
      latlng: [13.88333333, -60.96666666],
      timezones: ["America/St_Lucia"],
      _id: "5eabb192573ad310d849a65e",
      name: "Saint Lucia",
      country_code: "LC",
      capital: "Castries",
      is_service_provide: false,
    },
    {
      latlng: [47.26666666, 9.53333333],
      timezones: ["Europe/Vaduz"],
      _id: "5eabb192573ad310d849a65f",
      name: "Liechtenstein",
      country_code: "LI",
      capital: "Vaduz",
      dial_code: 423,
      is_service_provide: false,
    },
    {
      latlng: [7, 81],
      timezones: ["Asia/Colombo"],
      _id: "5eabb192573ad310d849a660",
      name: "Sri Lanka",
      country_code: "LK",
      capital: "Colombo",
      dial_code: 94,
      is_service_provide: false,
    },
    {
      latlng: [-29.5, 28.5],
      timezones: ["Africa/Maseru"],
      _id: "5eabb192573ad310d849a661",
      name: "Lesotho",
      country_code: "LS",
      capital: "Maseru",
      dial_code: 266,
      is_service_provide: false,
    },
    {
      latlng: [56, 24],
      timezones: ["Europe/Vilnius"],
      _id: "5eabb192573ad310d849a662",
      name: "Lithuania",
      country_code: "LT",
      capital: "Vilnius",
      dial_code: 370,
      is_service_provide: false,
    },
    {
      latlng: [49.75, 6.16666666],
      timezones: ["Europe/Luxembourg"],
      _id: "5eabb192573ad310d849a663",
      name: "Luxembourg",
      country_code: "LU",
      capital: "Luxembourg",
      dial_code: 352,
      is_service_provide: false,
    },
    {
      latlng: [57, 25],
      timezones: ["Europe/Riga"],
      _id: "5eabb192573ad310d849a664",
      name: "Latvia",
      country_code: "LV",
      capital: "Riga",
      dial_code: 371,
      is_service_provide: false,
    },
    {
      latlng: [22.16666666, 113.55],
      timezones: ["Asia/Macau"],
      _id: "5eabb192573ad310d849a665",
      name: "Macau",
      country_code: "MO",
      capital: null,
      dial_code: 853,
      is_service_provide: false,
    },
    {
      latlng: [32, -5],
      timezones: ["Africa/Casablanca"],
      _id: "5eabb192573ad310d849a666",
      name: "Morocco",
      country_code: "MA",
      capital: "Rabat",
      dial_code: 212,
      is_service_provide: false,
    },
    {
      latlng: [43.73333333, 7.4],
      timezones: ["Europe/Monaco"],
      _id: "5eabb192573ad310d849a667",
      name: "Monaco",
      country_code: "MC",
      capital: "Monaco",
      dial_code: 377,
      is_service_provide: false,
    },
    {
      latlng: [47, 29],
      timezones: ["Europe/Chisinau"],
      _id: "5eabb192573ad310d849a668",
      name: "Moldova",
      country_code: "MD",
      capital: "Chișinău",
      dial_code: 373,
      is_service_provide: false,
    },
    {
      latlng: [-20, 47],
      timezones: ["Indian/Antananarivo"],
      _id: "5eabb192573ad310d849a669",
      name: "Madagascar",
      country_code: "MG",
      capital: "Antananarivo",
      dial_code: 261,
      is_service_provide: false,
    },
    {
      latlng: [3.25, 73],
      timezones: ["Indian/Maldives"],
      _id: "5eabb192573ad310d849a66a",
      name: "Maldives",
      country_code: "MV",
      capital: "Malé",
      dial_code: 960,
      is_service_provide: false,
    },
    {
      latlng: [23, -102],
      timezones: [
        "America/Mexico_City",
        "America/Cancun",
        "America/Merida",
        "America/Monterrey",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Chihuahua",
        "America/Ojinaga",
        "America/Hermosillo",
        "America/Tijuana",
        "America/Bahia_Banderas",
      ],
      _id: "5eabb192573ad310d849a66b",
      name: "Mexico",
      country_code: "MX",
      capital: "Mexico City",
      dial_code: 52,
      is_service_provide: false,
    },
    {
      latlng: [9, 168],
      timezones: ["Pacific/Majuro", "Pacific/Kwajalein"],
      _id: "5eabb192573ad310d849a66c",
      name: "Marshall Islands",
      country_code: "MH",
      capital: "Majuro",
      dial_code: 692,
      is_service_provide: false,
    },
    {
      latlng: [41.83333333, 22],
      timezones: ["Europe/Skopje"],
      _id: "5eabb192573ad310d849a66d",
      name: "Macedonia",
      country_code: "MK",
      capital: "Skopje",
      dial_code: 389,
      is_service_provide: false,
    },
    {
      latlng: [17, -4],
      timezones: ["Africa/Bamako"],
      _id: "5eabb192573ad310d849a66e",
      name: "Mali",
      country_code: "ML",
      capital: "Bamako",
      dial_code: 223,
      is_service_provide: false,
    },
    {
      latlng: [35.83333333, 14.58333333],
      timezones: ["Europe/Malta"],
      _id: "5eabb192573ad310d849a66f",
      name: "Malta",
      country_code: "MT",
      capital: "Valletta",
      dial_code: 356,
      is_service_provide: false,
    },
    {
      latlng: [22, 98],
      timezones: ["Asia/Rangoon"],
      _id: "5eabb192573ad310d849a670",
      name: "Myanmar",
      country_code: "MM",
      capital: "Naypyidaw",
      dial_code: 95,
      is_service_provide: false,
    },
    {
      latlng: [42.5, 19.3],
      timezones: ["Europe/Podgorica"],
      _id: "5eabb192573ad310d849a671",
      name: "Montenegro",
      country_code: "ME",
      capital: "Podgorica",
      dial_code: 382,
      is_service_provide: false,
    },
    {
      latlng: [46, 105],
      timezones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
      _id: "5eabb192573ad310d849a672",
      name: "Mongolia",
      country_code: "MN",
      capital: "Ulan Bator",
      dial_code: 976,
      is_service_provide: false,
    },
    {
      latlng: [15.2, 145.75],
      timezones: ["Pacific/Saipan"],
      _id: "5eabb192573ad310d849a673",
      name: "Northern Mariana Islands",
      country_code: "MP",
      capital: "Saipan",
      is_service_provide: false,
    },
    {
      latlng: [-18.25, 35],
      timezones: ["Africa/Maputo"],
      _id: "5eabb192573ad310d849a674",
      name: "Mozambique",
      country_code: "MZ",
      capital: "Maputo",
      dial_code: 258,
      is_service_provide: false,
    },
    {
      latlng: [20, -12],
      timezones: ["Africa/Nouakchott"],
      _id: "5eabb192573ad310d849a675",
      name: "Mauritania",
      country_code: "MR",
      capital: "Nouakchott",
      dial_code: 222,
      is_service_provide: false,
    },
    {
      latlng: [16.75, -62.2],
      timezones: ["America/Montserrat"],
      _id: "5eabb192573ad310d849a676",
      name: "Montserrat",
      country_code: "MS",
      capital: "Plymouth",
      is_service_provide: false,
    },
    {
      latlng: [14.666667, -61],
      timezones: ["America/Martinique"],
      _id: "5eabb192573ad310d849a677",
      name: "Martinique",
      country_code: "MQ",
      capital: "Fort-de-France",
      dial_code: 596,
      is_service_provide: false,
    },
    {
      latlng: [-20.28333333, 57.55],
      timezones: ["Indian/Mauritius"],
      _id: "5eabb192573ad310d849a678",
      name: "Mauritius",
      country_code: "MU",
      capital: "Port Louis",
      dial_code: 230,
      is_service_provide: false,
    },
    {
      latlng: [-13.5, 34],
      timezones: ["Africa/Blantyre"],
      _id: "5eabb192573ad310d849a679",
      name: "Malawi",
      country_code: "MW",
      capital: "Lilongwe",
      dial_code: 265,
      is_service_provide: false,
    },
    {
      latlng: [2.5, 112.5],
      timezones: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
      _id: "5eabb192573ad310d849a67a",
      name: "Malaysia",
      country_code: "MY",
      capital: "Kuala Lumpur",
      dial_code: 60,
      is_service_provide: false,
    },
    {
      latlng: [-12.83333333, 45.16666666],
      timezones: ["Indian/Mayotte"],
      _id: "5eabb192573ad310d849a67b",
      name: "Mayotte",
      country_code: "YT",
      capital: "Mamoudzou",
      dial_code: 262,
      is_service_provide: false,
    },
    {
      latlng: [-22, 17],
      timezones: ["Africa/Windhoek"],
      _id: "5eabb192573ad310d849a67c",
      name: "Namibia",
      country_code: "NA",
      capital: "Windhoek",
      dial_code: 264,
      is_service_provide: false,
    },
    {
      latlng: [-21.5, 165.5],
      timezones: ["Pacific/Noumea"],
      _id: "5eabb192573ad310d849a67d",
      name: "New Caledonia",
      country_code: "NC",
      capital: "Nouméa",
      dial_code: 687,
      is_service_provide: false,
    },
    {
      latlng: [16, 8],
      timezones: ["Africa/Niamey"],
      _id: "5eabb192573ad310d849a67e",
      name: "Niger",
      country_code: "NE",
      capital: "Niamey",
      dial_code: 227,
      is_service_provide: false,
    },
    {
      latlng: [-29.03333333, 167.95],
      timezones: ["Pacific/Norfolk"],
      _id: "5eabb192573ad310d849a67f",
      name: "Norfolk Island",
      country_code: "NF",
      capital: "Kingston",
      dial_code: 672,
      is_service_provide: false,
    },
    {
      latlng: [10, 8],
      timezones: ["Africa/Lagos"],
      _id: "5eabb192573ad310d849a680",
      name: "Nigeria",
      country_code: "NG",
      capital: "Abuja",
      dial_code: 234,
      is_service_provide: false,
    },
    {
      latlng: [13, -85],
      timezones: ["America/Managua"],
      _id: "5eabb192573ad310d849a681",
      name: "Nicaragua",
      country_code: "NI",
      capital: "Managua",
      dial_code: 505,
      is_service_provide: false,
    },
    {
      latlng: [-19.03333333, -169.86666666],
      timezones: ["Pacific/Niue"],
      _id: "5eabb192573ad310d849a682",
      name: "Niue",
      country_code: "NU",
      capital: "Alofi",
      dial_code: 683,
      is_service_provide: false,
    },
    {
      latlng: [52.5, 5.75],
      timezones: ["Europe/Amsterdam"],
      _id: "5eabb192573ad310d849a683",
      name: "Netherlands",
      country_code: "NL",
      capital: "Amsterdam",
      dial_code: 31,
      is_service_provide: false,
    },
    {
      latlng: [62, 10],
      timezones: ["Europe/Oslo"],
      _id: "5eabb192573ad310d849a684",
      name: "Norway",
      country_code: "NO",
      capital: "Oslo",
      dial_code: 47,
      is_service_provide: false,
    },
    {
      latlng: [28, 84],
      timezones: ["Asia/Kathmandu"],
      _id: "5eabb192573ad310d849a685",
      name: "Nepal",
      country_code: "NP",
      capital: "Kathmandu",
      dial_code: 977,
      is_service_provide: false,
    },
    {
      latlng: [-0.53333333, 166.91666666],
      timezones: ["Pacific/Nauru"],
      _id: "5eabb192573ad310d849a686",
      name: "Nauru",
      country_code: "NR",
      capital: "Yaren",
      dial_code: 674,
      is_service_provide: false,
    },
    {
      latlng: [-41, 174],
      timezones: ["Pacific/Auckland", "Pacific/Chatham"],
      _id: "5eabb192573ad310d849a687",
      name: "New Zealand",
      country_code: "NZ",
      capital: "Wellington",
      dial_code: 64,
      is_service_provide: false,
    },
    {
      latlng: [21, 57],
      timezones: ["Asia/Muscat"],
      _id: "5eabb192573ad310d849a688",
      name: "Oman",
      country_code: "OM",
      capital: "Muscat",
      dial_code: 968,
      is_service_provide: false,
    },
    {
      latlng: [30, 70],
      timezones: ["Asia/Karachi"],
      _id: "5eabb192573ad310d849a689",
      name: "Pakistan",
      country_code: "PK",
      capital: "Islamabad",
      dial_code: 92,
      is_service_provide: false,
    },
    {
      latlng: [9, -80],
      timezones: ["America/Panama"],
      _id: "5eabb192573ad310d849a68a",
      name: "Panama",
      country_code: "PA",
      capital: "Panama City",
      dial_code: 507,
      is_service_provide: false,
    },
    {
      latlng: [-25.06666666, -130.1],
      timezones: ["Pacific/Pitcairn"],
      _id: "5eabb192573ad310d849a68b",
      name: "Pitcairn Islands",
      country_code: "PN",
      capital: "Adamstown",
      dial_code: 64,
      is_service_provide: false,
    },
    {
      latlng: [-10, -76],
      timezones: ["America/Lima"],
      _id: "5eabb192573ad310d849a68c",
      name: "Peru",
      country_code: "PE",
      capital: "Lima",
      dial_code: 51,
      is_service_provide: false,
    },
    {
      latlng: [13, 122],
      timezones: ["Asia/Manila"],
      _id: "5eabb192573ad310d849a68d",
      name: "Philippines",
      country_code: "PH",
      capital: "Manila",
      dial_code: 63,
      is_service_provide: false,
    },
    {
      latlng: [7.5, 134.5],
      timezones: ["Pacific/Palau"],
      _id: "5eabb192573ad310d849a68e",
      name: "Palau",
      country_code: "PW",
      capital: "Ngerulmud",
      dial_code: 680,
      is_service_provide: false,
    },
    {
      latlng: [-6, 147],
      timezones: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
      _id: "5eabb192573ad310d849a68f",
      name: "Papua New Guinea",
      country_code: "PG",
      capital: "Port Moresby",
      dial_code: 675,
      is_service_provide: false,
    },
    {
      latlng: [52, 20],
      timezones: ["Europe/Warsaw"],
      _id: "5eabb192573ad310d849a690",
      name: "Poland",
      country_code: "PL",
      capital: "Warsaw",
      dial_code: 48,
      is_service_provide: false,
    },
    {
      latlng: [18.25, -66.5],
      timezones: ["America/Puerto_Rico"],
      _id: "5eabb192573ad310d849a691",
      name: "Puerto Rico",
      country_code: "PR",
      capital: "San Juan",
      is_service_provide: false,
    },
    {
      latlng: [40, 127],
      timezones: ["Asia/Pyongyang"],
      _id: "5eabb192573ad310d849a692",
      name: "North Korea",
      country_code: "KP",
      capital: "Pyongyang",
      dial_code: 850,
      is_service_provide: false,
    },
    {
      latlng: [39.5, -8],
      timezones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
      _id: "5eabb192573ad310d849a693",
      name: "Portugal",
      country_code: "PT",
      capital: "Lisbon",
      dial_code: 351,
      is_service_provide: false,
    },
    {
      latlng: [-23, -58],
      timezones: ["America/Asuncion"],
      _id: "5eabb192573ad310d849a694",
      name: "Paraguay",
      country_code: "PY",
      capital: "Asunción",
      dial_code: 595,
      is_service_provide: false,
    },
    {
      latlng: [31.9, 35.2],
      timezones: ["Asia/Gaza", "Asia/Hebron"],
      _id: "5eabb192573ad310d849a695",
      name: "Palestine",
      country_code: "PS",
      capital: "Ramallah",
      dial_code: 970,
      is_service_provide: false,
    },
    {
      latlng: [-15, -140],
      timezones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
      _id: "5eabb192573ad310d849a696",
      name: "French Polynesia",
      country_code: "PF",
      capital: "Papeetē",
      dial_code: 689,
      is_service_provide: false,
    },
    {
      latlng: [25.5, 51.25],
      timezones: ["Asia/Qatar"],
      _id: "5eabb192573ad310d849a697",
      name: "Qatar",
      country_code: "QA",
      capital: "Doha",
      dial_code: 974,
      is_service_provide: false,
    },
    {
      latlng: [-21.15, 55.5],
      timezones: ["Indian/Reunion"],
      _id: "5eabb192573ad310d849a698",
      name: "Réunion",
      country_code: "RE",
      capital: "Saint-Denis",
      dial_code: 262,
      is_service_provide: false,
    },
    {
      latlng: [46, 25],
      timezones: ["Europe/Bucharest"],
      _id: "5eabb192573ad310d849a699",
      name: "Romania",
      country_code: "RO",
      capital: "Bucharest",
      dial_code: 40,
      is_service_provide: false,
    },
    {
      latlng: [60, 100],
      timezones: [
        "Europe/Kaliningrad",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Kirov",
        "Europe/Astrakhan",
        "Europe/Samara",
        "Europe/Ulyanovsk",
        "Asia/Yekaterinburg",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Barnaul",
        "Asia/Tomsk",
        "Asia/Novokuznetsk",
        "Asia/Krasnoyarsk",
        "Asia/Irkutsk",
        "Asia/Chita",
        "Asia/Yakutsk",
        "Asia/Khandyga",
        "Asia/Vladivostok",
        "Asia/Ust-Nera",
        "Asia/Magadan",
        "Asia/Sakhalin",
        "Asia/Srednekolymsk",
        "Asia/Kamchatka",
        "Asia/Anadyr",
      ],
      _id: "5eabb192573ad310d849a69a",
      name: "Russia",
      country_code: "RU",
      capital: "Moscow",
      dial_code: 7,
      is_service_provide: false,
    },
    {
      latlng: [-2, 30],
      timezones: ["Africa/Kigali"],
      _id: "5eabb192573ad310d849a69b",
      name: "Rwanda",
      country_code: "RW",
      capital: "Kigali",
      dial_code: 250,
      is_service_provide: false,
    },
    {
      latlng: [25, 45],
      timezones: ["Asia/Riyadh"],
      _id: "5eabb192573ad310d849a69c",
      name: "Saudi Arabia",
      country_code: "SA",
      capital: "Riyadh",
      dial_code: 966,
      is_service_provide: false,
    },
    {
      latlng: [15, 30],
      timezones: ["Africa/Khartoum"],
      _id: "5eabb192573ad310d849a69d",
      name: "Sudan",
      country_code: "SD",
      capital: "Khartoum",
      dial_code: 249,
      is_service_provide: false,
    },
    {
      latlng: [14, -14],
      timezones: ["Africa/Dakar"],
      _id: "5eabb192573ad310d849a69e",
      name: "Senegal",
      country_code: "SN",
      capital: "Dakar",
      dial_code: 221,
      is_service_provide: false,
    },
    {
      latlng: [1.36666666, 103.8],
      timezones: ["Asia/Singapore"],
      _id: "5eabb192573ad310d849a69f",
      name: "Singapore",
      country_code: "SG",
      capital: "Singapore",
      dial_code: 65,
      is_service_provide: false,
    },
    {
      latlng: [-54.5, -37],
      timezones: ["Atlantic/South_Georgia"],
      _id: "5eabb192573ad310d849a6a0",
      name: "South Georgia",
      country_code: "GS",
      capital: "King Edward Point",
      dial_code: 500,
      is_service_provide: false,
    },
    {
      latlng: [78, 20],
      timezones: ["Arctic/Longyearbyen"],
      _id: "5eabb192573ad310d849a6a1",
      name: "Svalbard and Jan Mayen",
      country_code: "SJ",
      capital: "Longyearbyen",
      dial_code: 470,
      is_service_provide: false,
    },
    {
      latlng: [-8, 159],
      timezones: ["Pacific/Guadalcanal"],
      _id: "5eabb192573ad310d849a6a2",
      name: "Solomon Islands",
      country_code: "SB",
      capital: "Honiara",
      dial_code: 677,
      is_service_provide: false,
    },
    {
      latlng: [8.5, -11.5],
      timezones: ["Africa/Freetown"],
      _id: "5eabb192573ad310d849a6a3",
      name: "Sierra Leone",
      country_code: "SL",
      capital: "Freetown",
      dial_code: 232,
      is_service_provide: false,
    },
    {
      latlng: [13.83333333, -88.91666666],
      timezones: ["America/El_Salvador"],
      _id: "5eabb192573ad310d849a6a4",
      name: "El Salvador",
      country_code: "SV",
      capital: "San Salvador",
      dial_code: 503,
      is_service_provide: false,
    },
    {
      latlng: [43.76666666, 12.41666666],
      timezones: ["Europe/San_Marino"],
      _id: "5eabb192573ad310d849a6a5",
      name: "San Marino",
      country_code: "SM",
      capital: "City of San Marino",
      dial_code: 378,
      is_service_provide: false,
    },
    {
      latlng: [10, 49],
      timezones: ["Africa/Mogadishu"],
      _id: "5eabb192573ad310d849a6a6",
      name: "Somalia",
      country_code: "SO",
      capital: "Mogadishu",
      dial_code: 252,
      is_service_provide: false,
    },
    {
      latlng: [46.83333333, -56.33333333],
      timezones: ["America/Miquelon"],
      _id: "5eabb192573ad310d849a6a7",
      name: "Saint Pierre and Miquelon",
      country_code: "PM",
      capital: "Saint-Pierre",
      dial_code: 508,
      is_service_provide: false,
    },
    {
      latlng: [44, 21],
      timezones: ["Europe/Belgrade"],
      _id: "5eabb192573ad310d849a6a8",
      name: "Serbia",
      country_code: "RS",
      capital: "Belgrade",
      dial_code: 381,
      is_service_provide: false,
    },
    {
      latlng: [1, 7],
      timezones: ["Africa/Sao_Tome"],
      _id: "5eabb192573ad310d849a6a9",
      name: "São Tomé and Príncipe",
      country_code: "ST",
      capital: "São Tomé",
      dial_code: 239,
      is_service_provide: false,
    },
    {
      latlng: [4, -56],
      timezones: ["America/Paramaribo"],
      _id: "5eabb192573ad310d849a6aa",
      name: "Suriname",
      country_code: "SR",
      capital: "Paramaribo",
      dial_code: 597,
      is_service_provide: false,
    },
    {
      latlng: [48.66666666, 19.5],
      timezones: ["Europe/Bratislava"],
      _id: "5eabb192573ad310d849a6ab",
      name: "Slovakia",
      country_code: "SK",
      capital: "Bratislava",
      dial_code: 421,
      is_service_provide: false,
    },
    {
      latlng: [46.11666666, 14.81666666],
      timezones: ["Europe/Ljubljana"],
      _id: "5eabb192573ad310d849a6ac",
      name: "Slovenia",
      country_code: "SI",
      capital: "Ljubljana",
      dial_code: 386,
      is_service_provide: false,
    },
    {
      latlng: [62, 15],
      timezones: ["Europe/Stockholm"],
      _id: "5eabb192573ad310d849a6ad",
      name: "Sweden",
      country_code: "SE",
      capital: "Stockholm",
      dial_code: 46,
      is_service_provide: false,
    },
    {
      latlng: [-26.5, 31.5],
      timezones: ["Africa/Mbabane"],
      _id: "5eabb192573ad310d849a6ae",
      name: "Swaziland",
      country_code: "SZ",
      capital: "Lobamba",
      dial_code: 268,
      is_service_provide: false,
    },
    {
      latlng: [-4.58333333, 55.66666666],
      timezones: ["Indian/Mahe"],
      _id: "5eabb192573ad310d849a6af",
      name: "Seychelles",
      country_code: "SC",
      capital: "Victoria",
      dial_code: 248,
      is_service_provide: false,
    },
    {
      latlng: [35, 38],
      timezones: ["Asia/Damascus"],
      _id: "5eabb192573ad310d849a6b0",
      name: "Syria",
      country_code: "SY",
      capital: "Damascus",
      dial_code: 963,
      is_service_provide: false,
    },
    {
      latlng: [21.75, -71.58333333],
      timezones: ["America/Grand_Turk"],
      _id: "5eabb192573ad310d849a6b1",
      name: "Turks and Caicos Islands",
      country_code: "TC",
      capital: "Cockburn Town",
      is_service_provide: false,
    },
    {
      latlng: [15, 19],
      timezones: ["Africa/Ndjamena"],
      _id: "5eabb192573ad310d849a6b2",
      name: "Chad",
      country_code: "TD",
      capital: "N'Djamena",
      dial_code: 235,
      is_service_provide: false,
    },
    {
      latlng: [8, 1.16666666],
      timezones: ["Africa/Lome"],
      _id: "5eabb192573ad310d849a6b3",
      name: "Togo",
      country_code: "TG",
      capital: "Lomé",
      dial_code: 228,
      is_service_provide: false,
    },
    {
      latlng: [15, 100],
      timezones: ["Asia/Bangkok"],
      _id: "5eabb192573ad310d849a6b4",
      name: "Thailand",
      country_code: "TH",
      capital: "Bangkok",
      dial_code: 66,
      is_service_provide: false,
    },
    {
      latlng: [39, 71],
      timezones: ["Asia/Dushanbe"],
      _id: "5eabb192573ad310d849a6b5",
      name: "Tajikistan",
      country_code: "TJ",
      capital: "Dushanbe",
      dial_code: 992,
      is_service_provide: false,
    },
    {
      latlng: [-9, -172],
      timezones: ["Pacific/Fakaofo"],
      _id: "5eabb192573ad310d849a6b6",
      name: "Tokelau",
      country_code: "TK",
      capital: "Fakaofo",
      dial_code: 690,
      is_service_provide: false,
    },
    {
      latlng: [40, 60],
      timezones: ["Asia/Ashgabat"],
      _id: "5eabb192573ad310d849a6b7",
      name: "Turkmenistan",
      country_code: "TM",
      capital: "Ashgabat",
      dial_code: 993,
      is_service_provide: false,
    },
    {
      latlng: [-8.83333333, 125.91666666],
      timezones: ["Asia/Dili"],
      _id: "5eabb192573ad310d849a6b8",
      name: "Timor-Leste",
      country_code: "TL",
      capital: "Dili",
      dial_code: 670,
      is_service_provide: false,
    },
    {
      latlng: [-20, -175],
      timezones: ["Pacific/Tongatapu"],
      _id: "5eabb192573ad310d849a6b9",
      name: "Tonga",
      country_code: "TO",
      capital: "Nuku'alofa",
      dial_code: 676,
      is_service_provide: false,
    },
    {
      latlng: [11, -61],
      timezones: ["America/Port_of_Spain"],
      _id: "5eabb192573ad310d849a6ba",
      name: "Trinidad and Tobago",
      country_code: "TT",
      capital: "Port of Spain",
      is_service_provide: false,
    },
    {
      latlng: [34, 9],
      timezones: ["Africa/Tunis"],
      _id: "5eabb192573ad310d849a6bb",
      name: "Tunisia",
      country_code: "TN",
      capital: "Tunis",
      dial_code: 216,
      is_service_provide: false,
    },
    {
      latlng: [39, 35],
      timezones: ["Europe/Istanbul"],
      _id: "5eabb192573ad310d849a6bc",
      name: "Turkey",
      country_code: "TR",
      capital: "Ankara",
      dial_code: 90,
      is_service_provide: false,
    },
    {
      latlng: [-8, 178],
      timezones: ["Pacific/Funafuti"],
      _id: "5eabb192573ad310d849a6bd",
      name: "Tuvalu",
      country_code: "TV",
      capital: "Funafuti",
      dial_code: 688,
      is_service_provide: false,
    },
    {
      latlng: [23.5, 121],
      timezones: ["Asia/Taipei"],
      _id: "5eabb192573ad310d849a6be",
      name: "Taiwan",
      country_code: "TW",
      capital: "Taipei",
      dial_code: 886,
      is_service_provide: false,
    },
    {
      latlng: [-6, 35],
      timezones: ["Africa/Dar_es_Salaam"],
      _id: "5eabb192573ad310d849a6bf",
      name: "Tanzania",
      country_code: "TZ",
      capital: "Dodoma",
      dial_code: 255,
      is_service_provide: false,
    },
    {
      latlng: [1, 32],
      timezones: ["Africa/Kampala"],
      _id: "5eabb192573ad310d849a6c0",
      name: "Uganda",
      country_code: "UG",
      capital: "Kampala",
      dial_code: 256,
      is_service_provide: false,
    },
    {
      latlng: [49, 32],
      timezones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
      _id: "5eabb192573ad310d849a6c1",
      name: "Ukraine",
      country_code: "UA",
      capital: "Kiev",
      dial_code: 380,
      is_service_provide: false,
    },
    {
      latlng: [19.2911437, 166.618332],
      timezones: ["Pacific/Johnston", "Pacific/Midway", "Pacific/Wake"],
      _id: "5eabb192573ad310d849a6c2",
      name: "United States Minor Outlying Islands",
      country_code: "UM",
      capital: null,
      dial_code: 246,
      is_service_provide: false,
    },
    {
      latlng: [-33, -56],
      timezones: ["America/Montevideo"],
      _id: "5eabb192573ad310d849a6c3",
      name: "Uruguay",
      country_code: "UY",
      capital: "Montevideo",
      dial_code: 598,
      is_service_provide: false,
    },
    {
      latlng: [38, -97],
      timezones: [
        "America/New_York",
        "America/Detroit",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Indiana/Indianapolis",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Vevay",
        "America/Chicago",
        "America/Indiana/Tell_City",
        "America/Indiana/Knox",
        "America/Menominee",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/North_Dakota/Beulah",
        "America/Denver",
        "America/Boise",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Anchorage",
        "America/Juneau",
        "America/Sitka",
        "America/Metlakatla",
        "America/Yakutat",
        "America/Nome",
        "America/Adak",
        "Pacific/Honolulu",
      ],
      _id: "5eabb192573ad310d849a6c4",
      name: "United States",
      country_code: "US",
      capital: "Washington D.C.",
      dial_code: 1,
      is_service_provide: false,
    },
    {
      latlng: [41, 64],
      timezones: ["Asia/Samarkand", "Asia/Tashkent"],
      _id: "5eabb192573ad310d849a6c5",
      name: "Uzbekistan",
      country_code: "UZ",
      capital: "Tashkent",
      dial_code: 998,
      is_service_provide: false,
    },
    {
      latlng: [41.9, 12.45],
      timezones: ["Europe/Vatican"],
      _id: "5eabb192573ad310d849a6c6",
      name: "Vatican City",
      country_code: "VA",
      capital: "Vatican City",
      dial_code: 379,
      is_service_provide: false,
    },
    {
      latlng: [13.25, -61.2],
      timezones: ["America/St_Vincent"],
      _id: "5eabb192573ad310d849a6c7",
      name: "Saint Vincent and the Grenadines",
      country_code: "VC",
      capital: "Kingstown",
      is_service_provide: false,
    },
    {
      latlng: [8, -66],
      timezones: ["America/Caracas"],
      _id: "5eabb192573ad310d849a6c8",
      name: "Venezuela",
      country_code: "VE",
      capital: "Caracas",
      dial_code: 58,
      is_service_provide: false,
    },
    {
      latlng: [18.431383, -64.62305],
      timezones: ["America/Tortola"],
      _id: "5eabb192573ad310d849a6c9",
      name: "British Virgin Islands",
      country_code: "VG",
      capital: "Road Town",
      is_service_provide: false,
    },
    {
      latlng: [18.35, -64.933333],
      timezones: ["America/St_Thomas"],
      _id: "5eabb192573ad310d849a6ca",
      name: "United States Virgin Islands",
      country_code: "VI",
      capital: "Charlotte Amalie",
      is_service_provide: false,
    },
    {
      latlng: [16.16666666, 107.83333333],
      timezones: ["Asia/Ho_Chi_Minh"],
      _id: "5eabb192573ad310d849a6cb",
      name: "Vietnam",
      country_code: "VN",
      capital: "Hanoi",
      dial_code: 84,
      is_service_provide: false,
    },
    {
      latlng: [-16, 167],
      timezones: ["Pacific/Efate"],
      _id: "5eabb192573ad310d849a6cc",
      name: "Vanuatu",
      country_code: "VU",
      capital: "Port Vila",
      dial_code: 678,
      is_service_provide: false,
    },
    {
      latlng: [-13.3, -176.2],
      timezones: ["Pacific/Wallis"],
      _id: "5eabb192573ad310d849a6cd",
      name: "Wallis and Futuna",
      country_code: "WF",
      capital: "Mata-Utu",
      dial_code: 681,
      is_service_provide: false,
    },
    {
      latlng: [-13.58333333, -172.33333333],
      timezones: ["Pacific/Apia"],
      _id: "5eabb192573ad310d849a6ce",
      name: "Samoa",
      country_code: "WS",
      capital: "Apia",
      dial_code: 685,
      is_service_provide: false,
    },
    {
      latlng: [15, 48],
      timezones: ["Asia/Aden"],
      _id: "5eabb192573ad310d849a6cf",
      name: "Yemen",
      country_code: "YE",
      capital: "Sana'a",
      dial_code: 967,
      is_service_provide: false,
    },
    {
      latlng: [-29, 24],
      timezones: ["Africa/Johannesburg"],
      _id: "5eabb192573ad310d849a6d0",
      name: "South Africa",
      country_code: "ZA",
      capital: "Pretoria",
      dial_code: 27,
      is_service_provide: false,
    },
    {
      latlng: [-15, 30],
      timezones: ["Africa/Lusaka"],
      _id: "5eabb192573ad310d849a6d1",
      name: "Zambia",
      country_code: "ZM",
      capital: "Lusaka",
      dial_code: 260,
      is_service_provide: false,
    },
    {
      latlng: [-20, 30],
      timezones: ["Africa/Harare"],
      _id: "5eabb192573ad310d849a6d2",
      name: "Zimbabwe",
      country_code: "ZW",
      capital: "Harare",
      dial_code: 263,
      is_service_provide: false,
    },
  ],
  country:
    [
      "Angola",
      "Anguilla",
      "Åland Islands",
      "Albania",
      "Andorra",
      "United Arab Emirates",
      "Argentina",
      "Armenia",
      "American Samoa",
      "Antarctica",
      "Antigua and Barbuda",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Burundi",
      "Belgium",
      "Benin",
      "Burkina Faso",
      "Bangladesh",
      "Bulgaria",
      "Bahrain",
      "Bahamas",
      "Bosnia and Herzegovina",
      "Belarus",
      "Belize",
      "Bermuda",
      "Bolivia",
      "Brazil",
      "Barbados",
      "Brunei",
      "Bhutan",
      "Bouvet Island",
      "Botswana",
      "Central African Republic",
      "Canada",
      "Cocos (Keeling) Islands",
      "Switzerland",
      "Chile",
      "China",
      "Ivory Coast",
      "Cameroon",
      "Republic of the Congo",
      "Cook Islands",
      "Colombia",
      "Comoros",
      "Cape Verde",
      "Costa Rica",
      "Cuba",
      "Curaçao",
      "Christmas Island",
      "Cayman Islands",
      "Cyprus",
      "Czech Republic",
      "Germany",
      "Djibouti",
      "Dominica",
      "Denmark",
      "Dominican Republic",
      "Algeria",
      "Ecuador",
      "Egypt",
      "Eritrea",
      "Western Sahara",
      "Spain",
      "Estonia",
      "Ethiopia",
      "Finland",
      "Fiji",
      "Falkland Islands",
      "France",
      "Faroe Islands",
      "Micronesia",
      "Gabon",
      "United Kingdom",
      "Georgia",
      "Guernsey",
      "Ghana",
      "Gibraltar",
      "Guinea",
      "Guadeloupe",
      "Gambia",
      "Guinea-Bissau",
      "Equatorial Guinea",
      "Greece",
      "Grenada",
      "Greenland",
      "Guatemala",
      "French Guiana",
      "Guam",
      "Guyana",
      "Hong Kong",
      "Honduras",
      "Croatia",
      "Haiti",
      "Hungary",
      "Indonesia",
      "Isle of Man",
      "India",
      "British Indian Ocean Territory",
      "Ireland",
      "Iran",
      "Iraq",
      "Iceland",
      "Israel",
      "Italy",
      "Jamaica",
      "Jersey",
      "Jordan",
      "Japan",
      "Kazakhstan",
      "Kenya",
      "Kyrgyzstan",
      "Cambodia",
      "Kiribati",
      "Saint Kitts and Nevis",
      "South Korea",
      "Kosovo",
      "Kuwait",
      "Laos",
      "Lebanon",
      "Liberia",
      "Libya",
      "Saint Lucia",
      "Liechtenstein",
      "Sri Lanka",
      "Lesotho",
      "Lithuania",
      "Luxembourg",
      "Latvia",
      "Macau",
      "Morocco",
      "Monaco",
      "Moldova",
      "Madagascar",
      "Maldives",
      "Mexico",
      "Marshall Islands",
      "Macedonia",
      "Mali",
      "Malta",
      "Myanmar",
      "Montenegro",
      "Mongolia",
      "Northern Mariana Islands",
      "Mozambique",
      "Mauritania",
      "Montserrat",
      "Martinique",
      "Mauritius",
      "Malawi",
      "Malaysia",
      "Mayotte",
      "Namibia",
      "New Caledonia",
      "Niger",
      "Norfolk Island",
      "Nigeria",
      "Nicaragua",
      "Niue",
      "Netherlands",
      "Norway",
      "Nepal",
      "Nauru",
      "New Zealand",
      "Oman",
      "Pakistan",
      "Panama",
      "Pitcairn Islands",
      "Peru",
      "Philippines",
      "Palau",
      "Papua New Guinea",
      "Poland",
      "Puerto Rico",
      "North Korea",
      "Portugal",
      "Paraguay",
      "Palestine",
      "French Polynesia",
      "Qatar",
      "Réunion",
      "Romania",
      "Russia",
      "Rwanda",
      "Saudi Arabia",
      "Sudan",
      "Senegal",
      "Singapore",
      "South Georgia",
      "Svalbard and Jan Mayen",
      "Solomon Islands",
      "Sierra Leone",
      "El Salvador",
      "San Marino",
      "Somalia",
      "Saint Pierre and Miquelon",
      "Serbia",
      "São Tomé and Príncipe",
      "Suriname",
      "Slovakia",
      "Slovenia",
      "Sweden",
      "Swaziland",
      "Seychelles",
      "Syria",
      "Turks and Caicos Islands",
      "Chad",
      "Togo",
      "Thailand",
      "Tajikistan",
      "Tokelau",
      "Turkmenistan",
      "Timor-Leste",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Tuvalu",
      "Taiwan",
      "Tanzania",
      "Uganda",
      "Ukraine",
      "United States Minor Outlying Islands",
      "Uruguay",
      "United States",
      "Uzbekistan",
      "Vatican City",
      "Saint Vincent and the Grenadines",
      "Venezuela",
      "British Virgin Islands",
      "United States Virgin Islands",
      "Vietnam",
      "Vanuatu",
      "Wallis and Futuna",
      "Samoa",
      "Yemen",
      "South Africa",
      "Zambia",
      "Zimbabwe",
    ],
  timezones: [],
  country_id: "",
  
  getCurrency: "",
  currency_symbol: "",
  currency: [
    "US dollars",
    "Canadian dollars",
    "euros",
    "UAE dirhams",
    "Afghan Afghanis",
    "Albanian lekë",
    "Armenian drams",
    "Argentine pesos",
    "Australian dollars",
    "Azerbaijani manats",
    "Bosnia-Herzegovina convertible marks",
    "Bangladeshi takas",
    "Bulgarian leva",
    "Bahraini dinars",
    "Burundian francs",
    "Brunei dollars",
    "Bolivian bolivianos",
    "Brazilian reals",
    "Botswanan pulas",
    "Belarusian rubles",
    "Belize dollars",
    "Congolese francs",
    "Swiss francs",
    "Chilean pesos",
    "Chinese yuan",
    "Colombian pesos",
    "Costa Rican colóns",
    "Cape Verdean escudos",
    "Czech Republic korunas",
    "Djiboutian francs",
    "Danish kroner",
    "Dominican pesos",
    "Algerian dinars",
    "Estonian kroons",
    "Egyptian pounds",
    "Eritrean nakfas",
    "Ethiopian birrs",
    "British pounds sterling",
    "Georgian laris",
    "Ghanaian cedis",
    "Guinean francs",
    "Guatemalan quetzals",
    "Hong Kong dollars",
    "Honduran lempiras",
    "Croatian kunas",
    "Hungarian forints",
    "Indonesian rupiahs",
    "Israeli new sheqels",
    "Indian rupees",
    "Iraqi dinars",
    "Iranian rials",
    "Icelandic krónur",
    "Jamaican dollars",
    "Jordanian dinars",
    "Japanese yen",
    "Kenyan shillings",
    "Cambodian riels",
    "Comorian francs",
    "South Korean won",
    "Kuwaiti dinars",
    "Kazakhstani tenges",
    "Lebanese pounds",
    "Sri Lankan rupees",
    "Lithuanian litai",
    "Latvian lati",
    "Libyan dinars",
    "Moroccan dirhams",
    "Moldovan lei",
    "Malagasy Ariaries",
    "Macedonian denari",
    "Myanma kyats",
    "Macanese patacas",
    "Mauritian rupees",
    "Mexican pesos",
    "Malaysian ringgits",
    "Mozambican meticals",
    "Namibian dollars",
    "Nigerian nairas",
    "Nicaraguan córdobas",
    "Norwegian kroner",
    "Nepalese rupees",
    "New Zealand dollars",
    "Omani rials",
    "Panamanian balboas",
    "Peruvian nuevos soles",
    "Philippine pesos",
    "Pakistani rupees",
    "Polish zlotys",
    "Paraguayan guaranis",
    "Qatari rials",
    "Romanian lei",
    "Serbian dinars",
    "Russian rubles",
    "Rwandan francs",
    "Saudi riyals",
    "Sudanese pounds",
    "Swedish kronor",
    "Singapore dollars",
    "Somali shillings",
    "Syrian pounds",
    "Thai baht",
    "Tunisian dinars",
    "Tongan paʻanga",
    "Turkish Lira",
    "Trinidad and Tobago dollars",
    "New Taiwan dollars",
    "Tanzanian shillings",
    "Ukrainian hryvnias",
    "Ugandan shillings",
    "Uruguayan pesos",
    "Uzbekistan som",
    "Venezuelan bolívars",
    "Vietnamese dong",
    "CFA francs BEAC",
    "CFA francs BCEAO",
    "Yemeni rials",
    "South African rand",
    "Zambian kwachas",
    "Zimbabwean Dollar",
  ],
};
const getCurrnency = (currency) => {
  const currency_data = JSON.parse(localStorage.getItem("currenciesData"));
  console.log("data_checking", currency_data, currency);
  return (
    currency_data &&
    currency_data.find((value) => value.name_plural == currency)
  );
};

const makeTimezone = (countries, country_name) => {
  console.log("here", countries, country_name);
  if (typeof country_name !== "undefined") {
    return countries.find((value) => value.name == country_name).timezones;
  } else {
    return [];
  }
};
const getCountryId = (countries, country_name) => {
  if (typeof country_name !== "undefined") {
    return countries.find((value) => value.name == country_name)._id;
  } else {
    return [];
  }
};

const makeCountry = (value) => {
  const x = [];
  value.map((value, index) => {
    x.push(value.name);
  });
  return x;
};

const Utility = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COUNTRY_TIMEZONE":
      return {
        ...state,
        data: action.payload,
        country: makeCountry(action.payload),
      };
    case "GET_TIMEZONE":
      return {
        ...state,
        timezones: makeTimezone(state.data, action.payload),
        country_id: getCountryId(state.data, action.payload),
      };
    case "GET_CURRENCY":
      console.log('action.payloadaction.payload',action.payload)
      return {
        ...state,
        getCurrency: getCurrnency(action.payload)?.code,
        currency_symbol: getCurrnency(action.payload)?.symbol_native,
      };
    default:
      return state;
  }
};

export default Utility;
