const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id:'',
  service_class_detail:{
    features:[""]
  },
  color:"#000000",
  active_service_class:[]

}
const handleFeature=(data)=>{
  return data
}

const handleActiveServiceCless = (data)=>{
  const x=[]
  data.map((value,index)=>{
    x.push({value:value._id,label:value.name})
  })
  return x
}
const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SERVICE_CLASS":
      return {
        ...state,
        data: handleFeature(action.data),
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end
      }
    case "GET_ALL_DATA":
      return {
        ...state,
      }
     case "SERVICE_CLASS_STATUS_LOADER":
       return{
         ...state,
         loading_id:action.payload.id,
         loading:action.payload.loading
       }
      case "GET_SERVICE_CLASS_DETAILS":
        return {
          ...state,
          service_class_detail:action.payload,
          color:action.color
        }
      
    
      case "GET_SERVICE_CLASS_ID":
        return {
          ...state,
          id:action.id
        }
        case "CHANGE_COLOR":
        return{
          ...state,
          color:action.payload
        }
        case "GET_ACTIVE_SERVICE_CLASS":
          return{
            ...state,
            active_service_class:action.payload
            // handleActiveServiceCless(action.payload)
          }
    default:
      return state
  }
}

export default DataListReducer
