const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id: "",
};
const PartnerCompanyPartner = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PARTNER_NEW_REGISTERED":
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    default:
      return state;
  }
};
export default PartnerCompanyPartner;
