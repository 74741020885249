export const login = (
  state = { isAuthenticated: false, userRole: "admin" },
  action
) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload.data, isAuthenticated: true };
    }
    case "LOGIN_WITH_ERROR": {
      console.log("LOGIN_WITH_ERROR");
      return { ...state, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};
