const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id: "",
  filter:"ALL",
  details:""
};

const arrangeData =(value)=>{
  const y=[];
  value.map(x=>{
    y.push({
      ...x,
      rating: Math.round(x.rating).toString()
    })
  })

  return y
}



const PartnerCompanyPartner = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PARTNER_COMPANY_PARTNER":
      return {
        ...state,
        data: arrangeData(action.data),
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
      case "GET_PARTNER_COMPANY_DETAILS":
        return{
          ...state,
          details:{partner:action.partner}
        }
      case "HANDLE_FILTER":{
        return{
          ...state,
          filter:action.payload
        }
      }
    default:
      return state;
  }
};
export default PartnerCompanyPartner;
