import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
} from "reactstrap";
import logoutSvgrepoCom from "../../../../assets/img/icons/logout-svgrepo-com.png";
import { logoutUser } from "../../../../redux/actions/auth/loginActions";
import logoutSvg from '../../../../assets/img/icons/logout.svg'

function LogoutModal({ openModal, toggle }) {
  const dispatch = useDispatch();
  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
      <Modal isOpen={openModal} toggle={toggle} centered backdrop={"static"}>
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody style={{ padding: "2rem 1rem" }}>
          <div className="text-center p-0">
            <img  width={'45px'} height={'45px'} src={logoutSvg} alt="..." />
          </div>
          <Row className="mx-2 mb-1">
            <Col xs="12 mt-1">
              <p
                style={{ fontSize: "larger", lineHeight: "4rem" }}
                className="text-center"
              >
                Are your sure you want to logout?
              </p>
            </Col>
            <Col xs="6">
              <button
                className="new-btn-style-outline"
                color="white"
                onClick={toggle}
                style={{ border: "1px solid #D9D9D9", width: "100%" }}
              >
                <div
                  className="font-weight-bold"
                  style={{ fontSize: "16px", color: "#000" }}
                >
                  Cancel
                </div>
              </button>
            </Col>
            <Col xs="6">
              <button
                className="new-btn-style"
                style={{ width: "100%" }}
                color="primary"
                onClick={() => {
                  dispatch(logoutUser());
                  // toggle()
                }}
              >
                <div className="font-weight-bold" style={{ fontSize: "16px" }}>
                  Logout
                </div>
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default LogoutModal;
