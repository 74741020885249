

export const loadingTrue = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_TRUE",
      action: true,
    });
  };
};
export const loadingFalse = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_FALSE",
      action: false,
    });
  };
};
export const loadingBookingChartTrue = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_BOOKING_CHART_TRUE",
      action: true,
    });
  };
};
export const loadingBookingChartFalse = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_BOOKING_CHART_FALSE",
      action: false,
    });
  };
};
export const loadingSidebarTrue = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_SIDEBAR_TRUE",
      action: true,
    });
  };
};
export const loadingSidebarFalse = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_SIDEBAR_FALSE",
      action: false,
    });
  };
};

export const loadingButtonTrue = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_BUTTON_TRUE",
      action: true,
    });
  };
};
export const loadingButtonFalse = () => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_BUTTON_FALSE",
      action: false,
    });
  };
};
