const initialState = {
    data: [],
    params: null,
    totalPages: 0,
    totalRecords: 0,
    id: "",
    location_detail: "",
  };
  
  const locationSelectOPtion = (locationArray)=>{
    const options = []
    const locationOption = locationArray?.map((item)=>{
      options.push({
        id:item?._id,name:item?.name
      })
    })
    return options;
  }
  
  const DataListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_CUSTOMERS":
        console.log('action123',action)
        return {
          ...state,
          customers: action.data,
          totalPages: action.totalPages,
          totalRecords: action.totalRecords,
          params: action.params,
          start: action.start,
          end: action.end,
          // bookings:action?.bookings
        //   locationforSelect: locationSelectOPtion(action.data)
        };

        case "GET_FILTER_CUSTOMERS":
          return {
            ...state,
            customers: action.data,
            totalPages: action.totalPages,
            totalRecords: action.totalRecords,
            params: action.params,
            start: action.start,
            end: action.end,
              
          }
      case "GET_ALL_DATA":
        return {
          ...state,
        };
  
      case "GET_LOCATION_DATA_BY_ID": {
        console.log("GET_LOCATION_DATA_BY_ID", action.payload);
        const dummy_location = JSON.parse(JSON.stringify(action.payload));
        const price = [];
        const times_service_class = [];
        console.log("dummy_location?.service_class", dummy_location);
  
        const vehicles = dummy_location?.service_class?.map((item) => {
          // price.push(item?.price[0]);
          times_service_class.push({
            service_class: item?.service_class_id,
            times: item?.hourly_rate,
          });
          price.push({ 
            basiccharge: item?.fix_charge, 
            perkmcharge: item?.per_km_charge,
            service_class:item?.service_class_id,
            service_class_name:item?.service_class_name 
          })
          return {
            service_class: item?.service_class_id,
            vehicle_model: item?.vehicle_model?.map((vItem) => ({
              value: vItem?._id,
              label: vItem?.vehicle_model_name,
            })),
          };
        });
  
        action.payload.vehicles = vehicles;
        action.payload.price = price;
        action.payload.times_service_class = times_service_class;
        
  
        console.log("item", action.payload);
  
        return {
          ...state,
          location_id: action.payload?._id,
          location_data_by_id: action.payload,
        };
      }
      case "CLEAR_LOCATION_DATA": {
        return {
          ...state,
          location_id: "",
          location_data_by_id: action.payload,
        };
      }
      case "LOCATION_STATUS_LOADER":
        return {
          ...state,
          loading_id: action.payload.id,
          loading: action.payload.loading,
        };
      case "GET_LOCATION_DETAILS":
        return {
          ...state,
          location_detail: action.payload,
        };
  
      case "GET_SERVICE_CLASS_ID":
        return {
          ...state,
          id: action.id,
        };
      default:
        return state;
    }
  };
  
  export default DataListReducer;
  