import Resturl from "../../../configs/url";

import axios from "axios";
import { loadingTrue, loadingFalse } from "../loading";
import { checkSign } from "../../../utility/sign";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, setInitialLoading) => {
  console.log(
    "🚀 ~ file: index.js:15 ~ getData ~ setInitialLoading:",
    setInitialLoading
  );
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
      offer_type: "schedule",
    };
  }

  return async (dispatch, getState) => {
    // dispatch(loadingTrue());

    const signature = await checkSign(params);
    await axios
      .post(Resturl.booking_list, params, {
        headers: {
          signature,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_NEW_BOOKINGS",
          all_offers_count: response.data.all_offers_count,
        });
        dispatch({
          type: "GET_COMPLETED_BOOKINGS",
          data: response.data.offers,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_offers,
          start: response.data.start,
          end: response.data.end,
          params,
        });
        if (setInitialLoading) setInitialLoading(false);
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
    // dispatch(loadingFalse());
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_DATA", value });
};

export const deleteData = (obj) => {
  return (dispatch) => {
    axios
      .post("/api/datalist/delete-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "DELETE_DATA", obj });
      });
  };
};

export const updateData = (obj) => {
  return (dispatch, getState) => {
    axios
      .post("/api/datalist/update-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "UPDATE_DATA", obj });
      });
  };
};

export const addData = (obj) => {
  return (dispatch, getState) => {
    let params = getState().dataList.params;
    axios
      .post("/api/datalist/add-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "ADD_DATA", obj });
        dispatch(getData(params));
      });
  };
};
export const getId = (id) => {
  return (dispatch) => {
    dispatch({
      type: "GET_OFFER_ID",
      id: id,
    });
  };
};

export const getDetail = () => {
  return async (dispatch, getState) => {
    let id = getState().offers.id;
    dispatch(loadingTrue());
    await axios.post(Resturl.offer_detail, { id: id }).then((response) => {
      dispatch({
        type: "GET_OFFER_DETAIL",
        offer_details: response.data.offers,
        //offer_price:response.data.offers
      });
    });
    dispatch(loadingFalse());
  };
};
