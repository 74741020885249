import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { createLogger } from "redux-logger";

const logger = createLogger({
  predicate: (getState, action) => !action?.type?.includes("@@redux-form"),

  //...other options
});

const middlewares = [thunk, createDebounce(), logger];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
