import Resturl from "../../../configs/url";

import axios from "axios";
export const locationCountry = () => {
  return function (dispatch, getState) {
    axios
      .post(Resturl.general_info)
      .then((response) => {
        console.log("🚀 ~ file: index.js:9 ~ .then ~ response:", response.data);
        dispatch({
          type: "GET_COUNTRY",
          payload: response.data,
        });
        localStorage.setItem(
          "currenciesData",
          JSON.stringify(response.data.currencies)
        );

        let currentLocationId = JSON.parse(localStorage.getItem("location"));
        console.log("currentLocationId", currentLocationId);
        dispatch(switchLocationCountry(currentLocationId?._id));
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const switchLocationCountry = (id) => {
  axios.defaults.headers.common["location_id"] = id;
  return function (dispatch, getState) {
    dispatch({
      type: "SWITCH_COUNTRY",
      payload: id,
    });
  };
};

export const getAllLocations = () => {
  return axios
    .post(Resturl.general_info)
    .then((response) => {
      localStorage.setItem(
        "location",
        JSON.stringify({
          name: response?.data?.locations[0]?.locations[0]?.name,
          _id: response?.data?.locations[0]?.locations[0]?._id,
          country_code: response?.data?.locations[0]?.country_code,
          currency: response?.data?.locations[0]?.locations[0]?.currency,
          countries: response?.data?.locations,
          currency_symbol:response?.data?.locations[0]?.locations[0]?.currency_symbol
        })
      );
      localStorage.setItem(
        "currenciesData",
        JSON.stringify(response.data.currencies)
      );
      return response;
    })
    .catch((err) => {
      console.log("🚀 ~ file: index.js:60 ~ getAllLocations ~ err:", err);
      return err;
    });
};
