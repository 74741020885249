export const forgotPassword = (
    state = {  },
    action
  ) => {
    switch (action.type) {
      case "FORGOTPASSWORD_WITH_EMAIL": {
        return { ...state, values: action.payload.data };
      }
      case "FORGOTPASSWORD_WITH_ERROR": {
        console.log("FORGOTPASSWORD_WITH_ERROR");
        return { ...state, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  };
  