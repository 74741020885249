const initialState = {
  data: [],
  serviceclass: [],
  bookingActiveTab: "first",
};

const CustomerListReducer = (state = initialState, action) => {
  console.log("🚀 ~ file: index.js:29 ~ CustomerListReducer ~ action:", action);
  switch (action.type) {
    case "CUSTOMER_LIST":
      return {
        ...state,
        data: action.payload.data,
      };
    case "SERVICE_CLASS":
      return {
        ...state,
        serviceclass: action.payload,
      };
    case "BOOKING_TYPE_TAB":
      return {
        ...state,
        bookingActiveTab: action.payload,
      };

    default:
      return state;
  }
};

export default CustomerListReducer;
