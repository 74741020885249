import axios from "axios";
import { DeviceUUID } from "device-uuid";

const setAuthToken = (token) => {
  // let currentLocationId = JSON.parse(localStorage.getItem("location"));
  // console.log("currentLocationId", currentLocationId?._id);
  var uuid = new DeviceUUID().get();
  var du = new DeviceUUID().parse();
  var dua = [du.language, du.os, du.cpuCores];
  var uuid = du.hashMD5(dua.join(":"));

  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.timeout = 60000;
    axios.defaults.headers.common["oauth"] = token;
    axios.defaults.headers.common["device_id"] = uuid;
    axios.defaults.headers.common["device_type"] = "WEB";
    axios.defaults.headers.common["device_name"] = du.browser;
    axios.defaults.headers.common["app_type"] = "Admin";
    axios.defaults.headers.common["device_version"] = du.version;
    axios.defaults.headers.common["os_name"] = du.os;
    axios.defaults.headers.common["app_version"] = "1";
    axios.defaults.headers.common["device_uuid"] =
      "0857fb90-ed03-11ea-b7fe-2f8a74dd2952";
    axios.defaults.headers.common["location_id"] =
      JSON.parse(localStorage.getItem("location"))?._id ?? "";
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["oauth"];
  }
};

export default setAuthToken;
