const initialState = {
  data: [],
  params: null,
  locationUpdateLoading: false,
  totalPages: 0,
  totalRecords: 0,
  id: "",
  offer_details: "",
  button_loading: false,
};

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOCATION_UPDATED_LOADING_HANDLED":
      return {
        ...state,
        locationUpdateLoading: action.payload?action.payload:false,
      };
    case "GET_NEW_BOOKINGS":
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        all_offers_count: action?.all_offers_count,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    case "GET_ALL_DATA":
      return {
        ...state,
        // allData: action.data,
        // totalRecords: action.data.length,
        // sortIndex: getIndex(action.data, state.data, state.sortIndex)
      };
    case "ASSIGN_PARTNER":
      return {
        ...state,
        button_loading: action.payload.button_loader,
        partner_id: action.payload.partner_id,
      };
    case "GET_OFFER_ID":
      return {
        ...state,
        id: action.id,
      };
    case "GET_OFFER_DETAIL":
      return {
        ...state,
        offer_details: action.offer_details,
        //offer_price:action.offer_price
      };
    default:
      return state;
  }
};

export default DataListReducer;
