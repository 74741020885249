export const checkSign = async(value) => {
    var crypto    = require('crypto');

    var text      = JSON.stringify(value);
    var secret    = '7e8cd5e7-6634-474d-b2ac-b8243bb2e87c'; //make this your secret!!
    var algorithm = 'sha1';   //consider using sha256
    var hash, hmac;
    
    // Method 1 - Writing to a stream
    hmac = crypto.createHmac(algorithm, secret);    
    hmac.write(text); // write in to the stream
    hmac.end();       // can't read from the stream until you call end()
    hash = await hmac.read().toString('hex');    // read out hmac digest
    return hash
    // Method 2 - Using update and digest:
    // hmac = crypto.createHmac(algorithm, secret);
    // hmac.update(text);
    // hash2 = await hmac.digest('hex');
  };