const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id: "",
  details: "",
  activeTab: "first",
};
const arrangeData = (value) => {
  value.rating = Math.round(value.rating).toString();
  return value;
};
const PartnerIndividualDriver = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };
    case "GET_PARTNER_INDIVIDUAL_DRIVER_TOTAL_COUNTS":
      return {
        ...state,
        total_counts: action.total_counts,
      };
    case "GET_PARTNER_INDIVIDUAL_DRIVER":
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
        total_counts: action.total_counts,
      };
    case "GET_PARTNER_INDIVIDUAL_DETAILS":
      return {
        ...state,
        details: {
          partner: action.partner,
          // driver: arrangeData(action.driver),
          driver: action.driver,
          vehicle: action.vehicle,
        },
      };
      case "INDIVIDUAL_DETAILS_STATUS_LOADER":
        return {
          ...state,
          loading_id: action.payload.id,
          loading: action.payload.loading,
        };
    default:
      return state;
  }
};
export default PartnerIndividualDriver;
