const initialState = {
  data: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  start:"",
  end:"",
};
const arrangeData =(value)=>{
  
  const y =[]
  value.map(x=>{
    var count=0;
    var vehicle_license=""
    var vehicle_insurance="";
    
    x.documents.map(z=>{
      
      
      if (z.file_type == "vehicle_license") {
        vehicle_license=z.file_path 
      }
      if (z.file_type == "vehicle_insurance") {
        vehicle_insurance=z.file_path 
      }
      count++
      if(count==2){
        y.push({
          _id:x._id,
          plate_number: x.plate_number,
      make:x.locations_vehicles_models.vehicle_model_make,
      model:x.locations_vehicles_models.vehicle_model_model,
      service_class:x.location_service_classes.service_class_name,
      color: x.location_service_classes.service_class_color,
      status:x.status,
      vehicle_license:vehicle_license,
      vehicle_insurance:vehicle_insurance,
      
      // vehicle_insurance:x.z[0].file_path,
      // vehicle_licence:x.z[1].file_path
    })
    console.log("zzzzzzzzzzzzzz",y)
  }

  

  // if (z.file_type == "vehicle_license") {
  //   vehicle_license=z.file_path 
  // }
  
})
  })
  return y
}

const PartnerDriver = (state = initialState, action) => {
  console.log('GET_PARTNER_VEHICLE123',action.data)
  switch (action.type) {
    case "GET_PARTNER_VEHICLE":
      return {
        ...state,
        data: arrangeData(action.data),
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    default:
      return state;
  }
};

export default PartnerDriver;
