const initialState = {
  loading:false,
  loadingSidebar:false,
  button_loading:false,
  loadingBookingChart:false
}


const Loading = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_TRUE":
      return {
        ...state,
        loading:true
      }
      case "LOADING_BOOKING_CHART_TRUE":
        return {
          ...state,
          loadingBookingChart:true
        }
      case "LOADING_BOOKING_CHART_FALSE":
        return {
          ...state,
          loadingBookingChart:false
        }
      case "LOADING_FALSE":
      return {
        ...state,
        loading:false
      }
      case "LOADING_SIDEBAR_TRUE":
      return {
        ...state,
        loadingSidebar:true
      }
      case "LOADING_SIDEBAR_FALSE":
      return {
        ...state,
        loadingSidebar:false
      }
      case "LOADING_BUTTON_TRUE":
      return {
        ...state,
        button_loading:true
      }
      case "LOADING_BUTTON_FALSE":
      return {
        ...state,
        button_loading:false
      }
   
    default:
      return state
  }
}

export default Loading
