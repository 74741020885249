const urlObj = {
  //login
  login_url: "/login",
  validate_auth: "/authentication",
  general_info: "/general-info",
  forgot_password: "/forgot-password",

  //service class
  service_class: "/serviceclass-list",
  add_service_class: "/add-serviceclass",
  update_service_class: "/update-serviceclass",
  service_class_detail: "/serviceclass",
  active_service_class: "/active-serviceclass",
  delete_service_class: "/serviceclass",

  //vehicle-model
  vehicle_model: "/vehicle-model-grid",
  add_vehicle_model: "/vehicle-model",
  vehicle_model_detail: "/vehicle-model",
  vehicle_makes: "/makes",
  active_vehicle_model: "/active-vehicle-models",
  delete_vehicle_model: "/vehicle-model",

  //location
  location: "/locations-grid",
  add_location: "/locations",
  location_api_url: "locations",
  location_detail: "/locations/5f3e2764efa47329c738f171",
  location_update: "/locations",

  //bookings
  booking_list: "/bookings-list",
  offer_detail: "/get-offer-detail",
  active_partner: "/active-partners",
  assign_partner: "/assign-partner",
  customer_list: "/all-customer",
  add_booking_url: "create-booking",
  cancel_booking: "cancel-booking",

  //partner
  partner_get_list: "/partners-grid",
  partner_details: "/partner-detail",
  pull_back: "/withdraw-partner",
  partner_status_update: "/partner-status-update",

  approve_partner_driver: "/driver-status",
  approve_partner_vehicle: "/vehicle-status",

  partner_drivers: "/partners-drivers",
  partner_vehicles: "/partners-vehicles",

  //utility
  country: "/country",

  //Dashboard
  dashboard_getCard: "dashboard-info",
  dashboard_revenue_value_chart: "revenue-value-chart",
  dashboard_booking_value_chart: "booking-value-chart",

  //Sub Admin
  sub_admin_list: "/sub-admin-list",
  add_sub_admin: "sub-admin",
  delete_sub_admin: "sub-admin",
  get_subAdmin_detail: "sub-admin",

  // customer
  customer_All_list: "customer-list",
  add_customer: "add-customer",

  // pricing
  add_pricing: "service-classes-price",
  get_service_classes_price: "service-classes-price",

  //reports
  revenue_reports: "revenue-report-chart",
  cancelled_reports: "cancelled-report",
  performance_report: "performance-report",
  invoice_report:'invoice-report'
};
module.exports = urlObj;
