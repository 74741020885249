import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
} from "reactstrap";
import classnames from "classnames";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
import SidebarRideFlexLogo from "../../../assets/img/logo/logo_rideflex_small.svg";
import logo_new from "../../../assets/img/logo/ic_ride_flex_logo_header.svg";
import Sidebar from "../menu/horizontal-menu/HorizontalMenu";
import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import moment from "moment";
import { switchLocationCountry } from "../../../redux/actions/country";
import { emptyReduxData } from "../../../redux/actions/new_bookings";
import { getData as getServiceData } from "../../../redux/actions/service_class";
import {
  getData as getNewBookings,
  locationUpdateLoading,
} from "../../../redux/actions/new_bookings";
import { getData as getAssignBookings } from "../../../redux/actions/assign_booking";
import { getData as getScheduleBookings } from "../../../redux/actions/completed_bookings";
import { getData as getCompletedBookings } from "../../../redux/actions/completed_bookings";
import { getData as getCancelledBookings } from "../../../redux/actions/cancelled_booking";
import { getData as getPartner } from "../../../redux/actions/partnerCompanyPatner";
import { getData as getNewRegister } from "../../../redux/actions/partnerNewRegistered";
import { getData as getIndividualRegister } from "../../../redux/actions/partnerIndividualDriver";
import { getData as getPartnerRegister } from "../../../redux/actions/partnerCompanyPatner";
import { getData as get_service_classes_price } from "../../../redux/actions/pricing";
import { useDispatch, useSelector } from "react-redux";
import {
  getCards,
  getRevenue,
  getBooking,
} from "../../../redux/actions/dashboard";

const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];

  const [langDropdown, setlLangDropdown] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  // const [country,setCountry] = useState('')

  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.partner_individual_driver);
  const bookingActiveTab = useSelector(
    (state) => state.add_booking.bookingActiveTab
  );

  useEffect(() => {
    setCountryCode(JSON.parse(localStorage.getItem("location"))?.country_code);
    setCountryName(JSON.parse(localStorage.getItem("location"))?.name);
  }, []);
  console.log("object123", langDropdown);
  const handleLangDropdown = () => {
    console.log("object123", langDropdown);
    setlLangDropdown(!langDropdown);
  };

  const country = useSelector((state) => state.country.country);
  const handleCountryChange = async (
    id,
    country_code,
    country_name,
    currency,
    currency_symbol
  ) => {
    console.log("object123");
    axios.defaults.headers.common["location_id"] = id;
    localStorage.setItem(
      "location",
      JSON.stringify({
        name: country_name,
        _id: id,
        country_code: country_code,
        currency: currency,
        currency_symbol: currency_symbol,
      })
    );
    setCountryCode(country_code);
    setCountryName(country_name);
    // this.setState({
    //   ...this.state,
    //   countryCode: country_code,
    //   countryName: country_name,
    // });
    console.log(window.location.pathname);
    dispatch(switchLocationCountry(id));
    if (window.location.pathname == "/offers") {
      // await this.props.getData();
    }

    if (window.location.pathname === "/sub-admin") {
      // this.props.locationUpdateLoading(true);
      // await this.props.getSubAdminData();
      // this.props.locationUpdateLoading(false);
    }
    // if (window.location.pathname == "/service-class") {
    //   await dispatch(getServiceData());
    // }
    if (window.location.pathname == "/bookings") {
      dispatch(emptyReduxData());
      let params = {
        page: 1,
        limit: 20,
        offer_type: "New",
      };
      const obj = {
        first: "New",
        second: "Assign",
        third: "schedule",
        fourth: "completed",
        fifth: "cancelled",
      };

      if (bookingActiveTab) {
        params.offer_type = obj[bookingActiveTab];
      }
      dispatch(locationUpdateLoading(true));
      if (obj[bookingActiveTab] === "New")
        await dispatch(getNewBookings(params));
      else if (obj[bookingActiveTab] === "Assign")
        await dispatch(getAssignBookings(params));
      else if (obj[bookingActiveTab] === "schedule")
        await dispatch(getScheduleBookings(params));
      else if (obj[bookingActiveTab] === "completed")
        await dispatch(getCompletedBookings(params));
      else if (obj[bookingActiveTab] === "cancelled")
        await dispatch(getCancelledBookings(params));

      dispatch(locationUpdateLoading(false));
    }
    if (window.location.pathname == "/partner") {
      dispatch(locationUpdateLoading(true));
      if (activeTab === "first") await dispatch(getIndividualRegister());
      else if (activeTab === "second") await dispatch(getPartnerRegister());
      else if (activeTab === "third") await dispatch(getNewRegister());

      locationUpdateLoading(false);
      // this.props.getPartner();
    }
    if (window.location.pathname == "/hourly-rates") {
      dispatch(locationUpdateLoading(true));
      await dispatch(get_service_classes_price());
      dispatch(locationUpdateLoading(false));
    }
    if (window.location.pathname == "/normal-rates") {
      dispatch(locationUpdateLoading(true));
      await dispatch(get_service_classes_price());
      dispatch(locationUpdateLoading(false));
    }
    if (window.location.pathname == "/") {
      dispatch(locationUpdateLoading(true));
      await dispatch(getCards());
      await dispatch(
        getRevenue({
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
        })
      );
      await dispatch(
        getBooking({
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
        })
      );
      dispatch(locationUpdateLoading(false));
    }
  };

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow bg-dark",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center mt-1"
              id="navbar-mobile"
            >
              {/* <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div> */}
              {props.horizontal ? (
                <div
                  className="d-flex align-items-center"
                  style={{ paddingLeft: "24px" }}
                >
                  <img
                    style={{ width: "50x", maxHeight: "50px" }}
                    src={logo_new}
                    alt="sidebar RideFlex logo"
                  />
                  {/* <div className="ml-1" style={{color:'#FBBC04',fontSize:'28px'}} >
                    RIDEFLEX
                  </div> */}
                  <div className="ml-2">
                    <Dropdown
                      // style={{ marginTop: "9px" }}
                      // tag="li"
                      className="dropdown-language nav-item"
                      isOpen={langDropdown}
                      toggle={handleLangDropdown}
                      data-tour="language"
                    >
                      <DropdownToggle
                        style={{
                          border: "1px solid #4E4E4E",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        tag="a"
                        className="nav-link"
                      >
                        <ReactCountryFlag
                          className="country-flag"
                          countryCode={countryCode}
                          svg
                        />
                        <span
                          style={{ marginRight: "25px" }}
                          className="d-sm-inline-block d-none text-capitalize align-middle ml-50"
                        >
                          {countryName}
                        </span>
                        <img
                          src={require("../../../assets/img/icons/down-arrow.svg")}
                          width="20px"
                          alt="down arrow icon"
                        />
                      </DropdownToggle>
                      {country.length == 0 ? null : (
                        <DropdownMenu
                          left
                          style={{ maxHeight: "300px", overflowY: "auto" }}
                        >
                          {country.map((value, index) =>
                            typeof value.locations !== "undefined"
                              ? value.locations.map((location) => (
                                  <div>
                                    <DropdownItem
                                      key={index}
                                      tag="a"
                                      onClick={() => {
                                        console.log("object123");
                                        handleCountryChange(
                                          location._id,
                                          value.country_code,
                                          location.name,
                                          location?.currency,
                                          location?.currency_symbol
                                        );
                                      }}
                                      className="dropdown_menu_dashboard"
                                    >
                                      <ReactCountryFlag
                                        className="country-flag"
                                        countryCode={value.country_code}
                                        svg
                                      />

                                      <span className="ml-1">
                                        {location.name + " "} (
                                        {value.country_name})
                                      </span>
                                    </DropdownItem>
                                  </div>
                                ))
                              : null
                          )}
                        </DropdownMenu>
                      )}
                    </Dropdown>
                  </div>
                </div>
              ) : // <div className="logo d-flex align-items-center">
              //   <div className="brand-logo mr-50"></div>
              //   <h2 className="text-primary brand-text mb-0">RideFlex</h2>
              // </div>
              null}

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="Demo test"
                userImg={userImg}
              />
            </div>
            <div>
              <Sidebar
                toggleSidebarMenu={props.toggleSidebarMenu}
                sidebarState={props.sidebarState}
                sidebarHover={props.sidebarHover}
                sidebarVisibility={props.sidebarVisibility}
                visibilityState={props.visibilityState}
                activePath={props.activePath}
                currentLang={props.currentLang}
                activeTheme={props.activeTheme}
                collapsed={props.collapsed}
                menuOpen={props.menuOpen}
                navbarType={props.navbarType}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default ThemeNavbar;
