import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import all_bookings from "./new_booking";
import add_booking from './add_booking'
import complete_bookings from "./complete_bookings";
import assigned_booking from "./assigned_booking";
import partners from "./partner/index";
import loading from "./loading/";
import service_class from "./service_class";
import company_partner from "./company-partner";
import individual_driver from "./individual-driver";
import country from "./country";
import location from "./location";
import { reducer as reduxFormReducer } from "redux-form";
import vehicle_model from "./vehicle_model";
import utility from "./utility";
import wizard from "./wizard";
import partner_company_partner from "./partnerCompanyPatner";
import partner_individual_driver from "./partnerIndividualDriver";
import partner_new_registered from "./partnerNewRegistered";
import partner_bookings from "./partnerBookings";
import partner_driver from "./partner_driver";
import partner_vehicle from "./partner_vehicle";
import dashboard from "./dashboard";
import subAdmin from './sub_admin'
import customer from './customer'
import pricing from './pricing'
import cancelled_booking from'./cancelled_booking'

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dashboard: dashboard,
  service_class: service_class,
  vehicle_model: vehicle_model,
  company_partner: company_partner,
  individual_driver: individual_driver,
  partner_company_partner: partner_company_partner,
  partner_individual_driver: partner_individual_driver,
  partner_new_registered: partner_new_registered,
  partner_driver: partner_driver,
  partner_vehicle: partner_vehicle,
  form: reduxFormReducer,
  new_bookings: all_bookings,
  add_booking:add_booking,
  complete_bookings: complete_bookings,
  cancelled_booking:cancelled_booking,
  assigned_booking: assigned_booking,
  partner_bookings: partner_bookings,
  partners: partners,
  country: country,
  location: location,
  utility: utility,
  loading: loading,
  wizard: wizard,
  sub_admin:subAdmin,
  customer:customer,
  pricing:pricing
});

export default rootReducer;
