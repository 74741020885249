import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "subadmin",
    title: "Sub Admin",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/sub-admin",
  },
  {
    id: "vehicle",
    title: "Vehicle Models",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/vehicle",
  },
  {
    id: "serviceclass",
    title: "Service Class",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/service-class",
  },
  {
    id: "location",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/location",
  },

  // {
  //   id: "driver",
  //   title: "Driver",
  //   type: "item",
  //   icon: <Icon.User size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/driver"
  // },
  {
    id: "customer",
    title: "Customers",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/customer",
  },
  {
    id: "partner",
    title: "Partners",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/partner",
  },
  // {
  //   id: "Add Booking",
  //   title: "Add Booking",
  //   type: "item",
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/add_booking",
  // },
  // {
  //   id: "booking",
  //   title: "Booking",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/offers"
  // },
  {
    id: "bookings",
    title: "Bookings",
    type: "collapse",
    icon: <Icon.Copy size={20} />,
    children: [
      {
        id: "new",
        title: "New",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/bookings/new",
      },
      {
        id: "scheduled",
        title: "Scheduled",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/bookings/finished",
      },
      {
        id: "Completed",
        title: "Completed",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/bookings/completed",
      },
    ],
  },
  // {
  //   id: "revenue",
  //   title: "Revenue",
  //   type: "item",
  //   icon: <Icon.DollarSign size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/revenue",
  // },
];

export default navigationConfig;
