import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "bookings",
    title: "Bookings",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/bookings",
  },
  // {
  //   id: "Add Booking",
  //   title: "Add Booking",
  //   type: "item",
  //   icon: <Icon.FilePlus size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/add_booking",
  // },
  {
    id: "partner",
    title: "Partners",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/partner",
  },
  {
    id: "vehicle",
    title: "Vehicle Models",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/vehicle",
  },
  {
    id: "serviceclass",
    title: "Service Class",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/service-class",
  },

  {
    id: "location",
    title: "Locations",
    type: "item",
    icon: <Icon.MapPin size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/location",
  },

  // {
  //   id: "bookings",
  //   title: "Bookings",
  //   type: "collapse",
  //   icon: <Icon.Copy size={20} />,
  //   children: [
  //     {
  //       id: "new",
  //       title: "New",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/bookings/new",
  //     },
  //     {
  //       id: "scheduled",
  //       title: "Scheduled",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/bookings/finished",
  //     },
  //     {
  //       id: "Completed",
  //       title: "Completed",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/bookings/completed",
  //     },
  //     {
  //       id: "cancelled",
  //       title: "Cancelled",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/bookings/cancelled",
  //     },

  //   ],
  // },
  {
    id: "pricing",
    title: "Pricing",
    type: "collapse",
    icon: <Icon.Copy size={20} />,
    children: [
      {
        id: "hourly_rates",
        title: "Hourly rates",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/hourly-rates",
      },
      {
        id: "normal_rates",
        title: "Normal rates",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/normal-rates",
      },
      // {
      //   id: "airport_rates",
      //   title: "Airport rates",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   // navLink: "/bookings/completed",
      // },
    ],
  },
  {
    id: "subadmin",
    title: "Sub Admin",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/sub-admin",
  },

  {
    id: "customer",
    title: "Customers",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/customer",
  },
  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/reports",
  },

  // {
  //   id: "pricing",
  //   title: "Pricing",
  //   type: "item",
  //   icon: <Icon.User size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/pricing"
  // },
  // {
  //   id: "revenue",
  //   title: "Revenue",
  //   type: "item",
  //   icon: <Icon.DollarSign size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/revenue",
  // },
];

export default navigationConfig;
