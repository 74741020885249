const initialState = {
    selected_location_service_class:[],
    active_service_class:[]
  }

  const selectedLocationServiceClass=(active_service_class,value)=>{
     const result= value.map((selected_service_class)=>{
          return active_service_class.find(value => value._id== selected_service_class.service_class)
      })
      return result
  }
 
  
  const DataListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SELECTED_LOCATION_SERVICE_CLASS":
        console.log('ssssssss',selectedLocationServiceClass(action.active_service_class,action.payload),action.active_service_class,action.payload)
        return {
          ...state,
          selected_location_service_class:selectedLocationServiceClass(action.active_service_class,action.payload),
          active_service_class:action.active_service_class
        };
      default:
        return state;
    }
  }
  
  export default DataListReducer
  