const initialState = {
  country: [],
  country_id: "",
};

const Country = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COUNTRY":
      const arrayString = JSON.stringify(action.payload.locations);
      localStorage.setItem("countries", arrayString);
      localStorage.setItem("roles", JSON.stringify(action.payload?.roles));
      return {
        ...state,
        country: action.payload.locations,
        roles: action.payload?.roles,
      };
    case "SWITCH_COUNTRY":
      return {
        ...state,
        country_id: action.payload,
      };
    default:
      return state;
  }
};

export default Country;
