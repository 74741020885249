import Resturl from "../../../configs/url";

import axios from "axios";
import {
  loadingTrue,
  loadingFalse,
  loadingSidebarTrue,
  loadingSidebarFalse,
  loadingButtonTrue,
  loadingButtonFalse,
} from "../loading";
import { checkSign } from "../../../utility/sign";
import { notification } from "../../../utility/notification";
import swal from "sweetalert";
import md5 from "md5";
import sha256 from "sha256";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, setInitialLoading) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
      filter: {
        sortBy: {
          column: "created_on",
          order: "desc",
        },
      },
    };
  }
  return async (dispatch) => {
    const signature = await checkSign(params);
    await axios
      .post(Resturl.sub_admin_list, params, {
        headers: {
          signature,
        },
      })
      .then((response) => {
        if (setInitialLoading) setInitialLoading(false);

        dispatch({
          type: "GET_SUB_ADMIN_LIST",
          data: response.data.sub_admins,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_results,
          start: response.data.start,
          end: response.data.end,
          params,
        });
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_DATA", value });
};

// export const filterData = (value) => {
//   let params = {
//     page: 1,
//     limit: 20,
//     search_data: value,
//   };
//   return async (dispatch) => {
//     await axios.post(Resturl.service_class, params).then((response) => {
//       dispatch({
//         type: 'GET_SERVICE_CLASS',
//         data: response.data.service_class,
//         totalPages: response.data.total_page,
//         totalRecords: response.data.total_serviceclass,
//         start: response.data.start,
//         end: response.data.end,
//         params,
//       });
//       dispatch({
//         type: "SERVICE_CLASS_STATUS_LOADER",
//         payload: { id: "", loading: false },
//       });
//     });
//   };
// };

export const deleteData = (id) => {
  console.log("id", Resturl);
  return (dispatch) => {
    axios
      .delete(Resturl.delete_sub_admin + "/" + id)
      .then((res) => {
        console.log("res", res);
        dispatch({ type: "DELETE_SERVICE_CLASS", payload: res });
        // swal("Deleted!", "Your service class has been deleted!", "success");
        dispatch(getData());
      })
      .catch((err) => {
        console.log("err=", err);
        dispatch({ type: "DELETE_SERVICE_CLASS_ERROR", payload: err.response });
        //notification("Vehicle Model",err.response.message,"danger")
      });
  };
};

export const updateData = (id, obj) => {
  return (dispatch, getState) => {
    console.log("obj", obj);
    dispatch(loadingButtonTrue());
    const param = {
      name: obj?.name, //   optional
      email: obj?.email, //   optional
      location_id: obj?.location?.value, //  optional
      password: sha256(md5(obj?.password)),
      country_code: obj?.contact.code,
      phone_number: obj?.contact.phone_number,
      role: obj?.role,
    };
    axios.put(`${Resturl.add_sub_admin}/${id}`, param).then((response) => {
      dispatch({ type: "ADD_SUB_ADMIN", payload: response, param });
      if (response?.data?.status == 1) {
        notification("Sub Admin", response.data.message, "success");
        dispatch({ type: "UPDATE_SUB_ADMIN", param });
        dispatch(loadingButtonFalse());
      } else {
        dispatch(loadingButtonFalse());
        notification("Sub Admin", response.data.message, "danger");
      }
    });
  };
};

export const addData = (obj) => {
  return (dispatch, getState) => {
    dispatch(loadingButtonTrue());
    const param = {
      name: obj?.name,
      email: obj?.email,
      location_id: obj?.location?.value,
      password: sha256(md5(obj?.password)),
      country_code: obj?.contact.code,
      phone_number: obj?.contact.phone_number,
      role: obj?.role,
    };

    axios.post(Resturl.add_sub_admin, param).then((response) => {
      dispatch({ type: "ADD_SUB_ADMIN", payload: response, param });
      console.log("response123456", response);
      if (response?.data?.status == 1) {
        dispatch(loadingButtonFalse());
        notification("Sub Admin", response.data.message, "success");
      } else {
        dispatch(loadingButtonFalse());
        notification("Sub Admin", response.data.message, "danger");
      }
    });
  };
};

export const getId = (id) => {
  return (dispatch) => {
    if (id == "") {
      dispatch({
        type: "GET_SERVICE_CLASS_DETAILS",
        payload: { features: [""] },
        color: "#000000",
      });
    } else {
      dispatch({
        type: "GET_SERVICE_CLASS_ID",
        id: id,
      });
    }
  };
};

export const getSubAdminDetail = (id) => {
  return async (dispatch, getState) => {
    dispatch(loadingSidebarTrue());
    await axios
      .get(`${Resturl.get_subAdmin_detail}/${id}`)
      .then((response) => {
        dispatch({
          type: "GET_SUB_ADMIN_DETAILS",
          payload: response.data.sub_admin,
        });
        dispatch(loadingSidebarFalse());
      })
      .catch((error) => {
        dispatch(loadingSidebarFalse());
      });
  };
};

export const changeColor = (color) => {
  return async (dispatch, getState) => {
    if (getState().service_class.id == "") {
      dispatch({
        type: "CHANGE_COLOR",
        payload: "#000000",
      });
    } else {
      dispatch({
        type: "CHANGE_COLOR",
        payload: color,
      });
    }

    // dispatch(loadingSidebarTrue())
    // await axios.get(`${Resturl.service_class_detail}/${getState().service_class.id}`).then((response) => {
    //   dispatch({
    //     type: "GET_SERVICE_CLASS_DETAILS",
    //     payload:response.data.service_class
    //   });
    //   dispatch(loadingSidebarFalse());
    // }).catch(error=>{

    //   dispatch(loadingSidebarFalse());
    // });
  };
};

export const changeStatus = (obj) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SERVICE_CLASS_STATUS_LOADER",
      payload: { id: obj.id, loading: true },
    });
    if (obj.status == "DEACTIVATED") {
      const param = {
        id: obj.id,
        status: "ACTIVE",
      };
      axios.put(Resturl.update_service_class, param).then((response) => {
        dispatch({ type: "CHANGE_SERVICE_CLASS_STATUS", param });
        dispatch(getData(getState().service_class.params));
      });
    } else {
      const param = {
        id: obj.id,
        status: "DEACTIVATED",
      };
      axios.put(Resturl.update_service_class, param).then((response) => {
        dispatch({ type: "CHANGE_SERVICE_CLASS_STATUS", param });
        dispatch(getData(getState().service_class.params));
      });
    }
  };
};

export const activeServiceClass = () => {
  return function (dispatch, getState) {
    axios.get(Resturl.active_service_class).then((response) => {
      dispatch({
        type: "GET_ACTIVE_SERVICE_CLASS",
        payload: response.data.service_class,
      });
    });
  };
};
