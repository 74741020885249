const initialState = {
  card_partner: { total: 0, individual: 0, company: 0 },
  card_customer: { total: 0, today_registered: 0 },
  card_bookings: {
    total: 0,
    booking_wating:0,
    booking_completed:0,
    // bookings_not_approved: 0,
    // bookings_not_assigned: 0,
    // assigned_but_not_accepted: 0,
  },
  card_approval_pending: { total: 0, company: 0, individual: 0 },

  revenue_label: [],
  revenue_present: [],
  revenue_past: [],
  revenue_present_earning: 0,
  revenue_past_earning: 0,

  booking_label: [],
  booking_cancelled:[],
  booking_completed:[],
  // booking_hourly: [],
  // booking_on_way_trip: [],
  booking_present_one_way_trip: 0,
  booking_present_hourly: 0,
  booking_past_one_way_trip: 0,
  booking_past_hourly: 0,
  booking_percentage: [],
};

const calculateRevenuePercentage = (present, past) => {
  console.log("increment1",present, past)
  if (parseInt(present) > parseInt(past)) {
    // this.setState({
      //   percentage_type: "increment",
      //   percentage_value: (((present - past) * 100) / present).toFixed(2),
      // });
      return ["increment", (((present - past) * 100) / present).toFixed(2)];
    } else if (parseInt(present) == parseInt(past)) {
    return ["increment", 0 
    // (((present - past) * 100) / present).toFixed(2)
  ];
  } else {
    //console.log("decrement",((present-past)*100)/present)
    // this.setState({
    //   percentage_type: "decrement",
    //   percentage_value: (((past - present) * 100) / past).toFixed(2),
    // });
    return ["decrement", (((past - present) * 100) / past).toFixed(2)];
  }
};
const calculateBookingPercentage = (
  present_one_way,
  present_hourly,
  past_one_way,
  past_hourly
) => {
  console.log('present_one_way123',present_one_way,present_hourly,past_one_way,past_hourly)
  const present = present_one_way ? present_one_way :0 + present_hourly ? present_hourly :0;
  const past = past_one_way?past_one_way:0 + past_hourly?past_hourly:0;
  if (present > past) {
    return ["increment", (((present - past) * 100) / present).toFixed(2)];
  } else if (present == past) {
    // return ["increment", (((present - past) * 100) / present).toFixed(2)];
    return ["increment", '0']
  } else {
    return ["decrement", (((past - present) * 100) / past).toFixed(2)];
  }
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_CARDS":
      return {
        ...state,
        card_partner: {
          total: action.payload.partner.total,
          individual: action.payload.partner.individual,
          company: action.payload.partner.company,
        },
        card_customer: {
          total: action.payload.customer.total,
          today_registered: action.payload.customer.today_registered,
        },
        card_bookings: {
          total: action.payload.bookings.total,
          booking_wating:action.payload.bookings.booking_wating,
          booking_completed:action.payload.bookings.booking_completed
          // bookings_not_approved: action.payload.bookings.bookings_not_approved,
          // bookings_not_assigned: action.payload.bookings.bookings_not_assigned,
          // assigned_but_not_accepted:
          // action.payload.bookings.assigned_but_not_accepted,
        },
        card_approval_pending: {
          total: action.payload.approval_pending.total,
          company: action.payload.approval_pending.company,
          individual: action.payload.approval_pending.individual,
        },
      };
    case "GET_DASHBOARD_REVENUE":
      console.log('action.payload.present_earning',action.payload.present_earning,action.payload.past_earning)
      return {
        ...state,
        revenue_label: action.payload.label,
        revenue_present: action.payload.present,
        revenue_past: action.payload.past,
        revenue_present_earning: action.payload.present_earning,
        revenue_past_earning: action.payload.past_earning,
        revenue_percentage: calculateRevenuePercentage(
          action.payload.present_earning?action.payload.present_earning:0,
          action.payload.past_earning?action.payload.past_earning:0
        ),
      };
    case "GET_DASHBOARD_BOOKINGS":
      console.log('action.payload1',calculateBookingPercentage(
        action.payload.present_one_side_trip,
        action.payload.present_hourly,
        action.payload.past_one_side_trip,
        action.payload.past_hourly
      ))
      return {
        ...state,
        booking_label: action.payload.label,
        // booking_cancelled:action.payload.booking_cancelled,
        // booking_completed:action.payload.booking_completed,
        booking_hourly: action.payload.hourly,
        booking_on_way_trip: action.payload.on_side_trip,
        booking_present_one_way_trip: action.payload.present_one_side_trip,
        booking_present_hourly: action.payload.present_hourly,
        booking_past_one_way_trip: action.payload.past_one_side_trip,
        booking_past_hourly: action.payload.past_hourly,
        booking_percentage: calculateBookingPercentage(
          action.payload.present_one_side_trip,
          action.payload.present_hourly,
          action.payload.past_one_side_trip,
          action.payload.past_hourly
        ),
      };
    default:
      return state;
  }
};

export default dashboard;
