const initialState = {
    data: [],
    params: null,
    totalPages: 0,
    totalRecords: 0,
    id: "",
    location_detail: "",
  };
  
  const locationSelectOPtion = (locationArray)=>{
    const options = []
    const locationOption = locationArray?.map((item)=>{
      options.push({
        id:item?._id,name:item?.name
      })
    })
    return options;
  }
  
  const DataListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PRICING":
        return {
          ...state,
          service_classes: action.data,
          service_class_location_info:action?.location_info,
          // totalPages: action.totalPages,
          // totalRecords: action.totalRecords,
          params: action.params,
          // start: action.start,
          // end: action.end,
          // locationforSelect: locationSelectOPtion(action.data)
        };
      case "GET_ALL_DATA":
        return {
          ...state,
        };
  
      case "GET_LOCATION_DATA_BY_ID": {
        console.log("GET_LOCATION_DATA_BY_ID", action.payload);
        const dummy_location = JSON.parse(JSON.stringify(action.payload));
        const price = [];
        const times_service_class = [];
        console.log("dummy_location?.service_class", dummy_location);
  
        const vehicles = dummy_location?.service_class?.map((item) => {
          // price.push(item?.price[0]);
          times_service_class.push({
            service_class: item?.service_class_id,
            times: item?.hourly_rate,
          });
          price.push({ 
            basiccharge: item?.fix_charge, 
            perkmcharge: item?.per_km_charge,
            service_class:item?.service_class_id,
            service_class_name:item?.service_class_name 
          })
          return {
            service_class: item?.service_class_id,
            vehicle_model: item?.vehicle_model?.map((vItem) => ({
              value: vItem?._id,
              label: vItem?.vehicle_model_name,
            })),
          };
        });
  
        // action.payload.vehicles = [
        //   {
        //     service_class: "5f4f77b57a8afc6698e635b8",
        //     vehicle_model: [
        //       {
        //         value: "64b0e00f93f70bce4e0cb482",
        //         label: "Acura MDX 2019",
        //       },
        //     ],
        //   },
        // ];
        action.payload.vehicles = vehicles;
        action.payload.price = price;
        action.payload.times_service_class = times_service_class;
        // action.payload.times_service_class = [
        //   {
        //     service_class_id: "64b0dd348d60dc48e0077441",
        //     times: [
        //       {
        //         hour: "1",
        //         km: "10",
        //         price: "100",
        //       },
        //       {
        //         hour: "2",
        //         km: "20",
        //         price: "200",
        //       },
        //       {
        //         hour: "3",
        //         km: "30",
        //         price: "300",
        //       },
        //       {
        //         hour: "4",
        //         km: "40",
        //         price: "400",
        //       },
        //       {
        //         hour: "5",
        //         km: "50",
        //         price: "500",
        //       },
        //       {
        //         hour: "6",
        //         km: "60",
        //         price: "600",
        //       },
        //       {
        //         hour: "7",
        //         km: "70",
        //         price: "700",
        //       },
        //       {
        //         hour: "8",
        //         km: "80",
        //         price: "800",
        //       },
        //       {
        //         hour: "9",
        //         km: "90",
        //         price: "900",
        //       },
        //       {
        //         hour: "10",
        //         km: "100",
        //         price: "1000",
        //       },
        //       {
        //         hour: "11",
        //         km: "110",
        //         price: "1100",
        //       },
        //       {
        //         hour: "12",
        //         km: "120",
        //         price: "1200",
        //       },
        //       {
        //         hour: "13",
        //         km: "130",
        //         price: "1300",
        //       },
        //       {
        //         hour: "14",
        //         km: "140",
        //         price: "1400",
        //       },
        //       {
        //         hour: "15",
        //         km: "150",
        //         price: "1500",
        //       },
        //       {
        //         hour: "16",
        //         km: "160",
        //         price: "1600",
        //       },
        //       {
        //         hour: "17",
        //         km: "170",
        //         price: "1700",
        //       },
        //       {
        //         hour: "18",
        //         km: "180",
        //         price: "1800",
        //       },
        //       {
        //         hour: "19",
        //         km: "190",
        //         price: "1900",
        //       },
        //       {
        //         hour: "20",
        //         km: "200",
        //         price: "2000",
        //       },
        //       {
        //         hour: "21",
        //         km: "210",
        //         price: "2100",
        //       },
        //       {
        //         hour: "22",
        //         km: "220",
        //         price: "2200",
        //       },
        //       {
        //         hour: "23",
        //         km: "230",
        //         price: "2300",
        //       },
        //       {
        //         hour: "24",
        //         km: "240",
        //         price: "2400",
        //       },
        //     ],
        //   },
        // ];
  
        console.log("item", action.payload);
  
        return {
          ...state,
          location_id: action.payload?._id,
          location_data_by_id: action.payload,
        };
      }
      case "CLEAR_LOCATION_DATA": {
        return {
          ...state,
          location_id: "",
          location_data_by_id: action.payload,
        };
      }
      case "LOCATION_STATUS_LOADER":
        return {
          ...state,
          loading_id: action.payload.id,
          loading: action.payload.loading,
        };
      case "GET_LOCATION_DETAILS":
        return {
          ...state,
          location_detail: action.payload,
        };
  
      case "GET_SERVICE_CLASS_ID":
        return {
          ...state,
          id: action.id,
        };
      default:
        return state;
    }
  };
  
  export default DataListReducer;
  