import { store } from "react-notifications-component";

export const notification = (title, message, type, position) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: position ? position : "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
};
