import Resturl from "../../../configs/url";
import axios from "axios";
import {
  loadingTrue,
  loadingFalse,
  loadingSidebarTrue,
  loadingSidebarFalse,
} from "../loading";
import { store } from "../../storeConfig/store";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const setActiveTab = (data) => {
  return (dispatch) => {
    dispatch({
      type: "GET_ACTIVE_TAB",
      payload: data,
    });
  };
};

export const getData = (params, setInitialLoading) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
      type: "ALL",
      filter: "Individual",
    };
  }
  return async (dispatch) => {
    // dispatch(loadingTrue());

    await axios
      .post(Resturl.partner_get_list, params)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_INDIVIDUAL_DRIVER",
          data: response.data.partners,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_partners,
          start: response.data.start,
          end: response.data.end,
          total_counts: response.data.total_counts,
          params,
        });
        if (setInitialLoading) setInitialLoading(false);
        dispatch(loadingFalse());
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
  };
};
export const getDetail = (params) => {
  return async (dispatch) => {
    store.dispatch(loadingSidebarTrue());
    await axios.post(Resturl.partner_details, params).then((response) => {
      dispatch({
        type: "GET_PARTNER_INDIVIDUAL_DETAILS",
        partner: response.data.partner,
        driver: response.data.driver,
        vehicle: response.data.vehicle,
      });
      store.dispatch(loadingSidebarFalse());
    });
  };
};
