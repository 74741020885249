import axios from "axios";
import setAuthToken from "../../../utility/setAuthToken";
import { DeviceUUID } from "device-uuid";
import Resturl from "../../../configs/url";
import { history } from "../../../history";
import { checkSign } from "../../../utility/sign";

import md5 from "md5";
import sha256 from "sha256";

import { loadingButtonTrue, loadingButtonFalse } from "../loading";
import { notification } from "../../../utility/notification";
import { getAllLocations, switchLocationCountry } from "../country";
// Login - get user token
export const loginUser = (userData, token) => async (dispatch) => {
  dispatch(loadingButtonTrue());
  var uuid = new DeviceUUID().get();
  var du = new DeviceUUID().parse();
  const obj = {
    email: userData.email,
    password: sha256(md5(userData.password)),
  };
  const signature = await checkSign(obj);
  let headers = {
    timeout: 20000,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      device_id: uuid,
      device_type: "WEB",
      device_name: du.browser,
      app_type: "Admin",
      device_version: du.version,
      os_name: du.os,
      app_version: 1,
      signature,
    },
  };
  axios
    .post(Resturl.login_url, { ...obj, token }, headers)
    .then((result) => {
      console.log("result_debug2", { userData, obj });
      if (result.data.status === 0) {
        dispatch(loadingButtonFalse());
        notification("Login", result.data.message, "danger");
        dispatch({
          type: "LOGIN_WITH_ERROR",
          payload: result.data,
        });
        dispatch(loadingButtonFalse());
        // notification("Login",result.data.message,"danger")
      } else {
        const user = {
          name: result.data.name,
          email: result.data.email,
          user_type: result.data.user_type,
          role: result.data.role,
        };

        const token = result.data.token;
        localStorage.setItem("Oauth", token);
        // localStorage.setItem("device_uuid", result.data.device_uuid);

        localStorage.setItem("User", JSON.stringify(user));
        // Set token to Auth header

        getAllLocations()
          .then((res) => {
            setAuthToken(token);
            dispatch(loadingButtonFalse());
            dispatch({
              type: "GET_COUNTRY",
              payload: res.data,
            });
            let currentLocationId = JSON.parse(
              localStorage.getItem("location")
            );
            dispatch(switchLocationCountry(currentLocationId?._id));
            notification("Login", "Login Successfully", "success");
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: result,
            });
          })
          .catch((err) => {
            dispatch(loadingButtonFalse());
          });
      }
    })
    .catch((error) => {
      // notification("Login", error.message, "danger");
      dispatch(loadingButtonFalse());
    });
};
// Set logged in user
export const setCurrentUser = (decoded) => async (dispatch) => {
  var uuid = new DeviceUUID().get();
  var du = new DeviceUUID().parse();
  let headers = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      device_id: uuid,
      device_type: "WEB",
      device_name: du.browser,
      app_type: "Admin",
      device_version: du.version,
      os_name: du.os,
      app_version: 1,
      oauth: decoded,
    },
  };

  const result = await axios
    .get(Resturl.validate_auth, headers)

    .then((result) => {
      if (result.data.status === 0) {
        // notification("Login..", result.data.message, "danger");
        dispatch({
          type: "LOGIN_WITH_ERROR",
          payload: result,
        });
      } else if (result.data.status === 401) {
        dispatch(logoutUser());
      } else {
        const user = {
          name: result.data.name,
          email: result.data.email,
          user_type: result.data.user_type,
          role: result.data.role,
        };
        const token = result.data.token;
        localStorage.setItem("Oauth", token);
        localStorage.setItem("device_uuid", result.data.device_uuid);
        localStorage.setItem("User", JSON.stringify(user));
        // notification("Login", result.data.message, "success");
        setAuthToken(token);
        dispatch({
          type: "LOGIN_WITH_EMAIL",
          payload: result,
        });
      }
    })
    .catch((error) => {
      // if (error.response.data.status === 401) {
      //   dispatch(logoutUser());
      // }
      // notification("Login", 'error', "danger");
      dispatch({
        type: "LOGIN_WITH_ERRORS",
        payload: error,
      });
    });
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("Oauth");
  localStorage.removeItem("location");
  localStorage.removeItem("User");
  localStorage.removeItem("countries");
  localStorage.removeItem("device_uuid");
  localStorage.removeItem("get_active_vehicle_model");
  localStorage.removeItem("roles");
  localStorage.removeItem("currenciesData")
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.reload("/login");
  // history.push("/login");
};
