import axios from "axios";
import Resturl from "../../../configs/url";
import { loadingBookingChartFalse, loadingBookingChartTrue, loadingFalse, loadingSidebarFalse, loadingSidebarTrue, loadingTrue } from "../loading";

export const getCards = (setInitialLoading) => {
  console.log("axios_axios1", axios.defaults.headers.common);
  return async (dispatch) => {
    // dispatch(loadingTrue());
    await axios
      .get(Resturl.dashboard_getCard)
      .then((result) => {
        console.log("result1", result?.data);
        dispatch({
          type: "GET_DASHBOARD_CARDS",
          payload: {
            partner: {
              total: result?.data?.totalpartner,
              individual: result?.data?.totalIndividualPartner,
              company: result?.data?.totalCompanyPartner,
            },
            customer: {
              total: result?.data?.totalCustomers,
              today_registered: result?.data?.totalCustomersTodayRegister,
            },
            bookings: {
              total: result?.data?.totalBookings,
              booking_wating: result?.data?.totalPendingBooking,
              booking_completed: result?.data?.totalCompletedBooking,
              // bookings_not_approved: 52,
              // bookings_not_assigned: 45,
              // assigned_but_not_accepted: 40,
            },
            approval_pending: {
              total: result?.data?.totalNewPartner,
              company: result?.data?.totalNewPartnerCompany,
              individual: result?.data?.totalNewPartnerIndividual,
            },
          },
        });
        if (setInitialLoading) setInitialLoading(false)
        // dispatch(loadingFalse());
      })
      .catch((error) => {
        // dispatch(loadingFalse());
        if (setInitialLoading) setInitialLoading(false)
        console.log(error);
      });
  };
};

export const getRevenue = (params,setInitialLoadingOther) => {
  // console.log('params',params)
  return async (dispatch, getState) => {
    // dispatch(loadingSidebarTrue());
    await axios
      .post(Resturl.dashboard_revenue_value_chart, params)
      .then((result) => {
        // const transformedData = result?.data?.totalRevenue.reduce((result, item) => {
        //   result._id.push(item._id);
        //   result.totalRevenue.push(item.totalRevenue.toFixed(2)); // Format revenue to 2 decimal places
        //   return result;
        // }, { _id: [], totalRevenue: [] });

        // console.log('transformedData',transformedData);

        dispatch({
          type: "GET_DASHBOARD_REVENUE",
          payload: {
            // label: [...getState().dashboard.revenue_label,"Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            // present: [...getState().dashboard.revenue_present,"43", "41", "49", "48", "40", "43", "46"],
            // past: [...getState().dashboard.revenue_past,"45", "47", "44", "47", "45", "48", "46"],
            //  label: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            //  present: ["43", "41", "49", "48", "40", "43", "46"],
            label: result?.data?.totalRevenue?.label,
            present: result?.data?.totalRevenue?.value,
            //  past: ["45", "47", "44", "47", "45", "48", "46"],
            present_earning: result?.data?.totalRevenue?.totalRevenue,
            past_earning: result?.data?.revenuePrivious?.totalRevenue,
          },
        });
        if (setInitialLoadingOther) setInitialLoadingOther(false)
        // dispatch(loadingSidebarFalse());
      })
      .catch((error) => {
        if (setInitialLoadingOther) setInitialLoadingOther(false)
        console.log(error);
      });
  };
};

export const getBooking = (params,setInitialLoadingOther) => {
  console.log("params12", params);
  return async (dispatch, getState) => {
    // dispatch(loadingBookingChartTrue())
    
    await axios
      .post(Resturl.dashboard_booking_value_chart, params)
      .then((result) => {
        dispatch({
          type: "GET_DASHBOARD_BOOKINGS",
          payload: {
            label: result?.data?.booking_date,
            hourly: result?.data?.hourly,
            on_side_trip: result?.data?.oneWay,
            present_one_side_trip:
              result?.data?.bookingsGrowthDataThis[0]?.count,
            present_hourly: result?.data?.bookingsGrowthDataThis[1]?.count,
            past_one_side_trip:
              result?.data?.bookingsGrowthDataPrivious[0]?.count,
            past_hourly: result?.data?.bookingsGrowthDataPrivious?.[1]?.count,
          },
        });
        // dispatch(loadingBookingChartFalse())
        if (setInitialLoadingOther) setInitialLoadingOther(false)
      })
      .catch((error) => {
        if (setInitialLoadingOther) setInitialLoadingOther(false)
        console.log("error12", error);
      });
  };
};
