import Resturl from "../../../configs/url";
import axios from "axios";
import {
  loadingTrue,
  loadingFalse,
  loadingSidebarTrue,
  loadingSidebarFalse,
} from "../loading";
import swal from "sweetalert";
import { getData as getAssignBooking } from "../new_bookings";
import { getData as getpartnerIndividualDriver } from "../partnerIndividualDriver";
import { notification } from "../../../utility/notification";
//import swal from ""
import { store } from "../../storeConfig/store";
import { ca } from "date-fns/locale";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, setInitialLoading) => {
  return async (dispatch, getState) => {
    if (isEmpty(params)) {
      params = {
        page: 1,
        limit: 20,
        type: "ALL",
        filter: "company",
      };
    }
    // dispatch(loadingTrue());
    await axios
      .post(Resturl.partner_get_list, params)
      .then((response) => {
        dispatch({
          type: "GET_PARTNER_INDIVIDUAL_DRIVER_TOTAL_COUNTS",
          total_counts: response.data.total_counts,
        });
        dispatch({
          type: "GET_PARTNER_COMPANY_PARTNER",
          data: response.data.partners,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_partners,
          start: response.data.start,
          end: response.data.end,
          params,
        });
        // dispatch(loadingFalse());
        if (setInitialLoading) setInitialLoading(false);
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
  };
};

export const getDetail = (params,setIntialLoading) => {
  return async (dispatch) => {
    store.dispatch(loadingSidebarTrue());
    await axios.post(Resturl.partner_details, params).then((response) => {
      // if (setIntialLoading) setIntialLoading(false)
      dispatch({
        type: "GET_PARTNER_COMPANY_DETAILS",
        partner: response.data.partner,
      });
      store.dispatch(loadingSidebarFalse());
    }).catch((err)=>{
      // if (setIntialLoading) setIntialLoading(false)
    });
  };
};

export const handleFilter = (value) => {
  return async (dispatch) => {
    //dispatch(loadingTrue());
    //  await axios.post(Resturl.partner_details, params).then((response) => {
    dispatch({
      type: "HANDLE_FILTER",
      payload: value,
    });
    dispatch(getData());
    // dispatch(loadingFalse());
    //});
  };
};

export const pullBack = (obj,setPullBackLoading) => {
  const cloneObj = JSON.parse(JSON.stringify(obj));
  delete cloneObj.callback;
  console.log("🚀 ~ file: index.js:77 ~ pullBack ~ cloneObj:", cloneObj.callback, obj);
  return async (dispatch) => {
    // swal({
    //   title: "Are you sure?",
    //   // text: "Once deleted, you will not be able to recover this imaginary file!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then((pullback) => {
    //   if (pullback) {
        axios
          .post(Resturl.pull_back, obj)
          .then((response) => {
            if (obj.callback) obj.callback();
            if (response.data.status == 0) {
              if (setPullBackLoading) setPullBackLoading()
              notification("Pull back", response.data.message, "danger");
            } else {
              dispatch({
                type: "PULL_BACK",
                payload: response,
              });
              dispatch(
                getAssignBooking({
                  page: 1,
                  limit: 20,
                  offer_type: "New",
                })
                );
            if (setPullBackLoading) setPullBackLoading()
            }
          })
          .catch((e) => {
            if (setPullBackLoading) setPullBackLoading()
            notification("Pull back", e.response.data.message, "danger");
          });
      // }
    // });
  };
};
export const partnerStatusUpdate = (id, status, callback, loaderForDots) => {
  return async (dispatch, getState) => {
    // console.log("here")
    //console.log("pull back booking",obj)

    // dispatch(loadingSidebarTrue())
    if (loaderForDots) loaderForDots(true, id);
    await axios
      .post(Resturl.partner_status_update, { partner_id: id, action: status })
      .then((response) => {
        dispatch({
          type: "PARTNER_STATUS_UPDATE",
          payload: response,
        });
        if (response.data.status === 0) {
          notification("Partner", response.data.message, "danger");
          if (loaderForDots) loaderForDots(false, id);
        } else {
          if (callback) callback(true);
          if (loaderForDots) loaderForDots(false);
          dispatch(getData({}, callback));
          dispatch(
            getData(getState().partner_company_partner.params, callback)
          );
          notification("Partner", response.data.message, "success");
        }

        // dispatch(loadingFalse());
      })
      .catch((err) => {
        if (loaderForDots) loaderForDots(false, id);
      });
  };
};
export const indivisalpartnerStatusUpdate = (
  id,
  status,
  callback,
  loaderForDots
) => {
  return async (dispatch, getState) => {
    if (loaderForDots) loaderForDots(true, id);
    await axios
      .post(Resturl.partner_status_update, { partner_id: id, action: status })
      .then((response) => {
        dispatch({
          type: "PARTNER_STATUS_UPDATE",
          payload: response,
        });

        if (response.data.status === 0) {
          notification("Partner", response.data.message, "danger");
          if (loaderForDots) loaderForDots(false, id);
        } else {
          if (callback) callback(true);
          dispatch(getpartnerIndividualDriver({}, callback));
          if (loaderForDots) loaderForDots(false, id);
          notification("Partner", response.data.message, "success");
        }
      })
      .catch((err) => {
        if (loaderForDots) loaderForDots(false);
      });
  };
};
