import Resturl from "../../../configs/url";

import axios from "axios";
// export const country = () => {
//   return function (dispatch, getState) {
//     axios.get(Resturl.country).then((response) => {
//       dispatch({
//         type: "GET_COUNTRY_TIMEZONE",
//         payload: response.data,
//       });
//     });
//   };
// };
export const changeTimezone = (value) => {
  return function (dispatch, getState) {
    dispatch({
      type: "GET_TIMEZONE",
      payload:value,
    });
  };
};
export const changeCurrency = (value) => {
  return function (dispatch, getState) {
    dispatch({
      type: "GET_CURRENCY",
      payload: value,
    });
  };
};
